import { ValidationService } from './../../services/validation.service';
import { AbstractControl, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'leaso-input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss'],
})
export class InputErrorComponent {
  @Input() submitted: boolean = false;
  @Input() control: FormControl | AbstractControl;

  constructor(public translate: TranslateService) {}

  get errorMessage(): any {
    const propertyName =
      this.control.errors && Object.keys(this.control.errors)?.length
        ? Object.keys(this.control?.errors)?.sort()[0]
        : null;
    if (propertyName && (this.control.dirty || this.control.touched || this.submitted)) {
      return ValidationService.getValidationErrorMessage(propertyName, this?.control?.errors![propertyName]);
    }
    return null;
  }
}
