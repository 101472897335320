import { Timer } from './timer.utils';
import { Observable } from 'rxjs';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Toast, ToastService } from './toast.service';
@Component({
  selector: 'leaso-toast',
  styleUrls: ['./toast.component.scss'],
  template: `<div class="toast-container" *ngIf="$toasts | async as toasts">
    <leaso-toast-item [toast]="toast" *ngFor="let toast of toasts; let i = index" [id]="i"></leaso-toast-item>
  </div>`,
})
export class ToastComponent implements OnInit {
  public $toasts: Observable<Toast[]>;
  public isCollapsed = true;

  constructor(private toastService: ToastService) {}

  ngOnInit() {
    this.$toasts = this.toastService.getAll();
  }
}

@Component({
  selector: 'leaso-toast-item',
  styleUrls: ['./toast.component.scss'],
  template: ` <div
    (mouseenter)="onPauseTimer()"
    (mouseleave)="onResumeTimer()"
    class="toast-item"
    [ngClass]="toast.type"
    [class.closed]="toast.isClosed"
  >
    <div class="toast-header">
      <img [src]="'assets/media/toast/ic-' + toast?.type + '.svg'" width="20px" height="20px" [alt]="toast?.title" />
      <h6 class="toast-title">{{ toast?.title }}</h6>
      <span
        class="toast-collapse"
        *ngIf="toast?.description"
        (click)="isCollapsed = !isCollapsed"
        [ngClass]="{
          expand: !isCollapsed
        }"
      >
      </span>
      <span class="toast-dismiss" (click)="dismissToast()"> </span>
    </div>
    <div class="toast-body" *ngIf="!isCollapsed">
      <p class="toast-description">
        {{ toast?.description }}
      </p>
    </div>
    <div class="toast-footer" *ngIf="toast?.actions?.length">
      <a class="toast-action" *ngFor="let action of toast?.actions" (click)="toast?.onClick(toast)">{{
        action?.content
      }}</a>
    </div>
  </div>`,
})
export class ToastItemComponent implements OnInit, OnDestroy {
  public isCollapsed = true;
  @Input() toast: Toast;
  @Input() id: number;
  timer;

  constructor(private toastService: ToastService) {}

  ngOnInit(): void {
    this.timer = new Timer(() => this.dismissToast(), this.toast.timer);
  }

  public dismissToast() {
    this.toast.isClosed = true;
    this.toastService.removeToast(this.id);
  }

  public onPauseTimer() {
    this.timer.pause();
  }

  public onResumeTimer() {
    this.timer.resume();
  }

  ngOnDestroy(): void {
    clearTimeout(this.timer);
  }
}
