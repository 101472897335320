import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHelperService } from '../../@shared/services/httpHelper.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _addedUser: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public addedUser$: Observable<boolean> = this._addedUser.asObservable();

  constructor(private httpHelperService: HttpHelperService, private httpClient: HttpClient) {}

  setUserHasBeenAdded(value: boolean) {
    this._addedUser.next(value);
  }

  getUserRoles() {
    return this.httpHelperService.get(`roles/list-data/list`);
  }

  editUser(id, payload) {
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this.httpHelperService.post(`users/${id}`, payload);
    // return this.httpClient.put(`${this.api_url}/${url}`, payload);
  }

  addUser(payload) {
    return this.httpHelperService.post(`users/`, payload);
  }

  getUsers(type, filters = {}) {
    return this.httpHelperService.get(`users/data/${type}`, filters);
  }

  getSessionTimeout() {
    return this.httpHelperService.get(`settings/getValue`);
  }

  getPermittedModule() {
    return this.httpHelperService.get(`settings/features`);
  }
}
