import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'upperTransform',
  pure: true,
})
export class UpperTransformPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    // console.log(value);
    if (typeof value !== 'string') return value;
    let arr = value?.split('');
    arr?.forEach((char, index) => {
      if (index == 0) arr[index] = arr[index].toUpperCase();
      if (char == '.' && arr[index + 1]) arr[index + 1] = arr[index + 1].toUpperCase();
    });
    return arr?.join('') ?? value;
  }
}
