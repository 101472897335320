import * as Rollbar from 'rollbar';
import { Injectable, Inject, InjectionToken, ErrorHandler } from '@angular/core';
import { environment } from '../../../environments/environment';

const rollbarConfig: Rollbar.Configuration = {
  accessToken: environment.RollbarAccessToken,
  captureUncaught: false,
  captureUnhandledRejections: false,
  environment: environment.envName,
  enabled: environment.envName !== 'localhost',
  nodeSourceMaps: true,
  codeVersion: environment.appVersion,
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any) {
    // FIXME
    console.error(err?.originalError || err);
    if (err?.originalError || err?.name != 'HttpErrorResponse') {
      this.rollbar.error(err?.originalError || err);
      // return throwError(error);
    }
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
