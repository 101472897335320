import { FormGroup, ValidationErrors, AbstractControl, ValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  constructor() {}
  /**
   * Get validation error message
   * @param validatorName control name
   * @param validatorValue validator
   * @returns error message
   */
  static getValidationErrorMessage(validatorName: string, validatorValue?: any): { key: string; params?: unknown } {
    let config = {
      required: {
        key: 'VALIDATION.REQUIRED',
      },
      email: {
        key: 'VALIDATION.EMAIL',
      },
      mustMatch: {
        key: 'VALIDATION.PASSWORD_MATCH',
      },
      minlength: {
        key: 'VALIDATION.MIN-LENGTH',
        params: { length: validatorValue?.requiredLength },
      },
      maxlength: {
        key: 'VALIDATION.MAX-LENGTH',
        params: { length: validatorValue?.requiredLength },
      },
      uniqueEmail: {
        key: 'VALIDATION.UNIQUE_EMAIL',
      },
      min: {
        key: 'VALIDATION.MIN',
        params: { min: validatorValue?.min },
      },
      max: {
        key: 'VALIDATION.MAX',
        params: { max: validatorValue?.max },
      },
      validatePhoneNumber: {
        key: 'VALIDATION.MOBILE_NUMBER',
      },
      matDatepickerMax: {
        key: 'VALIDATION.MAX_DATE',
        params: {
          max:
            validatorValue?.max && validatorName === 'matDatepickerMax'
              ? validatorValue?.max?.format('DD-MM-YYYY')
              : '',
        },
      },
      matDatepickerMin: {
        key: 'VALIDATION.MIN_DATE',
        params: {
          min:
            validatorValue?.min && validatorName === 'matDatepickerMin'
              ? validatorValue?.min?.format('DD-MM-YYYY')
              : '',
        },
      },
      lookup: {
        key: 'VALIDATION.LOOKUP',
      },
      password_pattern: {
        key: 'VALIDATION.INVALID_PASSWORD',
      },
      matDatepickerParse: {
        key: 'VALIDATION.DATE_FORMAT',
      },
      alphabetic: {
        key: 'VALIDATION.ALPHABETS',
      },
      alphaNumeric: {
        key: validatorValue?.language === 'en' ? 'VALIDATION.ALPHANUMERIC' : 'VALIDATION.ALPHANUMERIC_AR',
      },
      onlyNumbers: {
        key: 'VALIDATION.ONLY_NUMBERS',
      },
    };

    return config[validatorName as keyof typeof config];
  }

  /**
   * Checking control validation
   * @param form form group
   * @param controlName form control name
   * @param validationType validator
   * @param submitted is form submitted
   * @returns valid or not
   */
  static isControlHasError(
    form: FormGroup,
    controlName: string,
    validationType?: string,
    submitted: boolean = false
  ): boolean {
    const control = form.controls[controlName];
    return !control
      ? false
      : validationType
      ? control.hasError(validationType)
      : control.invalid && (control.dirty || control.touched || submitted);
  }

  static MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  static ShouldPickLookup(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
      control.value?.id ? null : { lookup: control.value };
  }

  static PasswordValidator(): ValidatorFn {
    /**
     * Password should contain at least
     * 1 [A-Z]
     * 1 [a-z]
     * 1 [0-9]
     * 1 Special Character
     * 8 Min Length
     * 15 Max Length
     */
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()-+={}\[\]|\\:;"'<>,.?/_])[A-Za-z\d~`!@#$%^&*()-+={}\[\]|\\:;"'<>,.?/_]{8,15}$/;
    return (control: AbstractControl): { [key: string]: any } | null =>
      control?.value && control?.value?.trim() && regex.test(control.value)
        ? null
        : { password_pattern: control.value };
  }

  static AlphabeticValidator(allowSpace: boolean = false): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const regex = allowSpace ? /^[a-zA-Z ]+$/ : /^[a-zA-Z]+$/;
      return control.value && regex.test(control.value) ? null : { alphabetic: { value: control.value, allowSpace } };
    };
  }

  static AlphaNumericValidator(allowSpace: boolean = false, language: 'en' | 'ar' = 'en'): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let regex: RegExp;
      switch (language) {
        case 'en':
          regex = allowSpace ? /^[a-zA-Z0-9 ]+$/ : /^[a-zA-Z0-9]+$/;
          break;
        case 'ar':
          regex = allowSpace ? /^[\u0621-\u064A\u0660-\u06690-9 ]+$/ : /^[\u0621-\u064A\u0660-\u06690-9]+$/;
          break;
      }

      return (control.value?.length && regex.test(control.value)) || !control.value?.length
        ? null
        : { alphaNumeric: { value: control.value, allowSpace, language } };
    };
  }

  static OnlyNumbersValidators(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const regex = /^[0-9]+$/;
      return control.value && regex.test(control.value) ? null : { onlyNumbers: control.value };
    };
  }
}
