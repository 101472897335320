/* eslint-disable @angular-eslint/no-output-native */
// Angular
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';
// Auth
import { AuthNotice, AuthNoticeService } from '../../../@core/auth/';

@Component({
  selector: 'leaso-leaso-kt-auth-notice',
  templateUrl: './auth-notice.component.html',
})
export class AuthNoticeComponent implements OnInit, OnDestroy {
  @Output() type: any;
  @Output() message: String = '';

  // Private properties
  private subscriptions: Subscription[] = [];

  /**
   * Component Constructure
   *
   * @param authNoticeService
   * @param cdr
   */
  constructor(public authNoticeService: AuthNoticeService, private cdr: ChangeDetectorRef) {}

  /*
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    this.subscriptions.push(
      this.authNoticeService.onNoticeChanged$.subscribe((notice: AuthNotice) => {
        notice = Object.assign({}, { message: '', type: '' }, notice);
        this.message = notice.message;
        this.type = notice.type;
      })
    );
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }
}
