import { TableFiltersComponent, TableFiltersLabelComponent } from './components/table-filters/table-filters.component';
import { SelectComponent } from './components/select/select.component';
import { ToastModule } from './components/toastr/toast.module';
import { InputErrorComponent } from './components/input-error/input-error.component';
import { DataTableComponent } from './data-table/data-table.component';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
// Angular
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Translation
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

// NgBootstrap
import { NgbDropdownModule, NgbTooltipModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// Core module
import { CoreModule } from '../@core/core.module';
// CRUD Partials
import {
  ActionNotificationComponent,
  AlertComponent,
  DeleteEntityDialogComponent,
  FetchEntityDialogComponent,
  UpdateStatusDialogComponent,
} from './crud';
// Layout partials
import { ContextMenu2Component, ContextMenuComponent, SearchResultComponent } from './layout';
// General
// Errpr
import { ErrorComponent } from './error/error.component';
// Extra module
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
// Dropzone
import { DropzoneModule, DROPZONE_CONFIG, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { NgxSmartModalModule } from 'ngx-smart-modal';

import { ObjectKeysPipe } from './pipes/object-keys.pipe';

import { CurrencyMaskModule } from 'ng2-currency-mask';

import { SlimLoadingBarModule } from '@cime/ngx-slim-loading-bar';

import { NgxBootstrapSwitchModule } from 'ngx-bootstrap-switch';
import { NgxPaginationModule } from 'ngx-pagination';

import { SelectDropDownModule } from 'ngx-select-dropdown';
import { ContractTasksModalComponent } from './modals/contract-tasks-modal/contract-tasks-modal.component';
import { EnquiryModalComponent } from './modals/enquiry-modal/enquiry-modal.component';
import { CustomerFinancialInfoComponent } from './components/customer-financial-info/customer-financial-info.component';
import { CustomerFinancialInfoModalComponent } from './modals/customer-financial-info-modal/customer-financial-info-modal.component';
import { UploadTaxModalComponent } from './modals/upload-tax-modal/upload-tax-modal.component';
import { CustomerInfoLiabilityTableComponent } from './components/customer-info-liability-table/customer-info-liability-table.component';
import { MatStepperModule } from '@angular/material/stepper';
import { LeasingCalculatorComponent } from './components/leasing-calculator/leasing-calculator.component';
import { MarketPriceaAddCalculatorComponent } from './components/market-pricea-add-calculator/market-pricea-add-calculator.component';
import { AddUpdateVehicleComponent } from './components/add-update-vehicle/add-update-vehicle.component';
import { LeasingCalculatorTabComponent } from './components/leasing-calculator-tab/leasing-calculator-tab.component';
import { AddProductModalComponent } from './modals/add-product-modal/add-product-modal.component';
import { AddCostProductComponent } from './modals/add-cost-product/add-cost-product.component';
import { CalculationResultComponent } from './components/calculation-result/calculation-result.component';
import { VehicleInformationTabComponent } from './components/vehicle-information-tab/vehicle-information-tab.component';
import { VehicleInfoAddVehicleComponent } from './components/vehicle-info-add-vehicle/vehicle-info-add-vehicle.component';
import { ContractsFormViewCustomerComponent } from './components/contracts-form-view-customer/contracts-form-view-customer.component';
import { CustomerInformationTabComponent } from './components/customer-information-tab/customer-information-tab.component';
import { CustomerTabAddCustomerComponent } from './components/customer-tab-add-customer/customer-tab-add-customer.component';
import { ContractCreatedTabComponent } from './components/contract-created-tab/contract-created-tab.component';
import { DealsCustomerVehicleTabComponent } from './components/deals-customer-vehicle-tab/deals-customer-vehicle-tab.component';
import { DealsFormVehicleComponent } from './components/deals-form-vehicle/deals-form-vehicle.component';
import { DealsFormCustomerComponent } from './components/deals-form-customer/deals-form-customer.component';
import { DealsCreatedTabComponent } from './components/deals-created-tab/deals-created-tab.component';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { InternalRateModalComponent } from './modals/internal-rate-modal/internal-rate-modal.component';
import { ContractsFormContractCrerationModalComponent } from './modals/contracts-form-contract-creration-modal/contracts-form-contract-creration-modal.component';
import { RequestEuVatRefundModalComponent } from './modals/request-eu-vat-refund-modal/request-eu-vat-refund-modal.component';
import { ContractFormAddTransactionsEndContractModalComponent } from './modals/contract-form-add-transactions-end-contract-modal/contract-form-add-transactions-end-contract-modal.component';
import { RergisterationTaxTaskModalComponent } from './modals/rergisteration-tax-task-modal/rergisteration-tax-task-modal.component';
import { AddCustomerEndTaskModalComponent } from './modals/add-customer-end-task-modal/add-customer-end-task-modal.component';
import { RevenuFromProductsModalComponent } from './modals/revenu-from-products-modal/revenu-from-products-modal.component';
import { CostProductModalComponent } from './modals/cost-product-modal/cost-product-modal.component';
import { SelectCreateCustomerModalComponent } from './modals/select-create-customer-modal/select-create-customer-modal.component';
import { InternalRateComponent } from './modals/internal-rate-modal/internal-rate/internal-rate.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';

import { MatExpansionModule } from '@angular/material/expansion';

import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { CounterInputComponent } from '../@shared/components/counter-input/counter-input.component';
import { CustomSelectComponent } from './components/custom-select/custom-select.component';
import { SearchCustomerByCvrComponent } from './components/search-customer-by-cvr/search-customer-by-cvr.component';
import { SharedDocumentsComponent } from './components/shared-documents/shared-documents.component';
import { AddSharedDocumentModalComponent } from './modals/add-shared-document-modal/add-shared-document-modal.component';
import { SearchVehiclesByDmrComponent } from './components/search-vehicles-by-dmr/search-vehicles-by-dmr.component';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { PerventOpenContractTasksComponent } from './modals/pervent-open-contract-tasks/pervent-open-contract-tasks.component';
import { HttpClient } from '@angular/common/http';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PrintCustomerCvrDocComponent } from './components/print-customer-cvr-doc/print-customer-cvr-doc.component';
import { UploadCustomerIdManagementModalComponent } from './modals/upload-customer-id-management-modal/upload-customer-id-management-modal.component';
import { DeleteTransactionDialogModalComponent } from './modals/delete-transaction-dialog-modal/delete-transaction-dialog-modal.component';
import { ContractsFormAddTransactionModalComponent } from '../pages/contracts/contracts-form-add-transaction-modal/contracts-form-add-transaction-modal.component';
import { VehicleDetailsDataComponent } from './components/vehicle-details-data/vehicle-details-data.component';
import { VehicleFieldsComponent } from './components/vehicle-fields/vehicle-fields.component';
import { KycLogsModalComponent } from './modals/kyc-logs-modal/kyc-logs-modal.component';
import { QuestionnaireSettingsModalComponent } from './modals/questionnaire-settings-modal/questionnaire-settings-modal.component';
import { UpperTransformPipe } from './pipes/upper-transform.pipe';
import { LinkCustomerToParticipantModalComponent } from './modals/link-customer-to-participant-modal/link-customer-to-participant-modal.component';
import { EditRealOwnerModalComponent } from './modals/edit-real-owner-modal/edit-real-owner-modal.component';
import { DndModule } from 'ngx-drag-drop';
import { CustomerFieldsComponent } from './components/customer-fields/customer-fields.component';
import { ContractTransactionsComponent } from './components/contract-transactions/contract-transactions.component';
import { CopyClipboardComponent } from './components/copy-clipboard/copy-clipboard.component';
import { EmptyDashedPipe } from './pipes/empty-dash.pipe';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://spark-api.b5digital.dk/api/',
  acceptedFiles: 'image/*',
  maxFilesize: 20,
  autoProcessQueue: false,
  addRemoveLinks: true,
  dictRemoveFile: '<i class="fa fa-times"></i>',
};

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM-YYYY',
  },
};

@NgModule({
  declarations: [
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    ContractCreatedTabComponent,
    DealsCustomerVehicleTabComponent,
    DealsFormVehicleComponent,
    DealsFormCustomerComponent,
    DealsCreatedTabComponent,
    AlertComponent,
    ContextMenu2Component,
    ContextMenuComponent,
    SearchResultComponent,
    DataTableComponent,
    ErrorComponent,
    ObjectKeysPipe,
    UpperTransformPipe,
    EnquiryModalComponent,
    ContractTasksModalComponent,
    CustomerFinancialInfoComponent,
    CustomerFinancialInfoModalComponent,
    UploadTaxModalComponent,
    CustomerInfoLiabilityTableComponent,
    LeasingCalculatorComponent,
    MarketPriceaAddCalculatorComponent,
    AddUpdateVehicleComponent,
    LeasingCalculatorTabComponent,
    AddProductModalComponent,
    AddCostProductComponent,
    CalculationResultComponent,
    VehicleInformationTabComponent,
    VehicleInfoAddVehicleComponent,
    ContractsFormViewCustomerComponent,
    CustomerInformationTabComponent,
    CustomerTabAddCustomerComponent,
    InternalRateModalComponent,
    ContractsFormContractCrerationModalComponent,
    RequestEuVatRefundModalComponent,
    ContractFormAddTransactionsEndContractModalComponent,
    RergisterationTaxTaskModalComponent,
    AddCustomerEndTaskModalComponent,
    RevenuFromProductsModalComponent,
    CostProductModalComponent,
    SelectCreateCustomerModalComponent,
    InternalRateComponent,
    ConfirmDialogComponent,
    CounterInputComponent,
    CustomSelectComponent,
    SearchCustomerByCvrComponent,
    SharedDocumentsComponent,
    AddSharedDocumentModalComponent,
    SearchVehiclesByDmrComponent,
    PerventOpenContractTasksComponent,
    PrintCustomerCvrDocComponent,
    UploadCustomerIdManagementModalComponent,
    DeleteTransactionDialogModalComponent,
    ContractsFormAddTransactionModalComponent,
    VehicleDetailsDataComponent,
    VehicleFieldsComponent,
    KycLogsModalComponent,
    QuestionnaireSettingsModalComponent,
    LinkCustomerToParticipantModalComponent,
    EditRealOwnerModalComponent,
    InputErrorComponent,
    SelectComponent,
    TableFiltersComponent,
    TableFiltersLabelComponent,
    CustomerFieldsComponent,
    ContractTransactionsComponent,
    CopyClipboardComponent,
    EmptyDashedPipe
  ],
  exports: [
    DndModule,
    TranslateModule,
    InternalRateComponent,
    ContractCreatedTabComponent,
    DealsCustomerVehicleTabComponent,
    DealsFormCustomerComponent,
    DealsFormVehicleComponent,
    DealsCreatedTabComponent,
    RequestEuVatRefundModalComponent,
    ContractFormAddTransactionsEndContractModalComponent,
    RergisterationTaxTaskModalComponent,
    AddCustomerEndTaskModalComponent,
    RevenuFromProductsModalComponent,
    CostProductModalComponent,
    SelectCreateCustomerModalComponent,
    InternalRateModalComponent,
    SearchCustomerByCvrComponent,
    SearchVehiclesByDmrComponent,
    VehicleDetailsDataComponent,
    VehicleFieldsComponent,
    KycLogsModalComponent,
    LinkCustomerToParticipantModalComponent,
    EditRealOwnerModalComponent,
    InputErrorComponent,
    SelectComponent,
    TableFiltersComponent,
    TableFiltersLabelComponent,
    // angular material modules
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,
    MatStepperModule,
    MatSlideToggleModule,

    FormsModule,
    ReactiveFormsModule,

    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    AlertComponent,
    // topbar components
    ContextMenu2Component,
    ContextMenuComponent,
    SearchResultComponent,
    EnquiryModalComponent,
    ContractTasksModalComponent,
    CustomerFinancialInfoComponent,
    CustomerFinancialInfoModalComponent,
    UploadTaxModalComponent,
    CustomerInfoLiabilityTableComponent,
    LeasingCalculatorComponent,
    MarketPriceaAddCalculatorComponent,
    AddUpdateVehicleComponent,
    LeasingCalculatorTabComponent,
    AddProductModalComponent,
    AddCostProductComponent,
    CalculationResultComponent,
    VehicleInformationTabComponent,
    VehicleInfoAddVehicleComponent,
    ContractsFormViewCustomerComponent,
    CustomerInformationTabComponent,
    CustomerTabAddCustomerComponent,
    ContractsFormContractCrerationModalComponent,
    PerventOpenContractTasksComponent,
    PrintCustomerCvrDocComponent,
    DeleteTransactionDialogModalComponent,
    CustomerFieldsComponent,
    ContractTransactionsComponent,
    CopyClipboardComponent,

    // angular material modules
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,
    MatStepperModule,

    // external module
    DropzoneModule,
    ErrorComponent,
    DataTableComponent,
    NgxSmartModalModule,
    NgbModule,
    ObjectKeysPipe,
    UpperTransformPipe,
    CurrencyMaskModule,
    SlimLoadingBarModule,
    NgxBootstrapSwitchModule,
    SelectDropDownModule,
    NgSelectModule,
    MatExpansionModule,
    AngularMultiSelectModule,
    CounterInputComponent,
    CustomSelectComponent,
    SharedDocumentsComponent,
    AddSharedDocumentModalComponent,
    LoadingBarModule,
    UploadCustomerIdManagementModalComponent,
    ContractsFormAddTransactionModalComponent,
    QuestionnaireSettingsModalComponent,
    ToastModule,
    EmptyDashedPipe
  ],
  imports: [
    DndModule,
    TranslateModule.forChild(),
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    InlineSVGModule,
    // angular material modules
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,
    MatSlideToggleModule,
    LoadingBarModule,
    // external module
    DropzoneModule,
    TranslateModule.forChild(),
    SlimLoadingBarModule.forRoot(),

    // ng-bootstrap modules
    NgbDropdownModule,
    NgbTooltipModule,
    NgxSmartModalModule,
    NgbModule,
    CurrencyMaskModule,
    NgxBootstrapSwitchModule,
    NgxPaginationModule,
    SelectDropDownModule,
    MatStepperModule,
    NgSelectModule,
    MatExpansionModule,
    AngularMultiSelectModule,
    PdfViewerModule,
    TranslateModule.forChild(),
    ToastModule,
  ],
  providers: [
    DatePipe,
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'da' },
  ],
  entryComponents: [ActionNotificationComponent, DeleteEntityDialogComponent, ConfirmDialogComponent],
})
export class SharedModule {}
