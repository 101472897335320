<!-- <ng-select #ngSelect name="selectValue" [items]="optionsSelect" bindLabel="label"
    bindValue="value" [(ngModel)]="mapBind[propertyName]" [disabled]="disabled" ></ng-select> -->

<!-- <ng-select #ngSelect name="selectValue" [(ngModel)]="mapBind[propertyName]" [disabled]="disabled">
  <ng-option *ngFor="let option of optionsSelect" [value]="option.value">{{option.label}}</ng-option>
</ng-select> -->
<ng-container *ngIf="!mapBind">
  <ng-select [clearable]="false" #ngSelect name="selectValue" [(ngModel)]="propertyName" [disabled]="disabled">
    <ng-option *ngFor="let option of optionsSelect" [value]="option.value">{{ option.label }}</ng-option>
  </ng-select>
</ng-container>

<ng-container *ngIf="mapBind">
  <ng-select [clearable]="false" #ngSelect name="selectValue" [(ngModel)]="mapBind[propertyName]" [disabled]="disabled">
    <ng-option *ngFor="let option of optionsSelect" [value]="option.value">{{ option.label }}</ng-option>
  </ng-select>
</ng-container>

<!-- val:{{mapBind[propertyName]}} -->
<!-- list:{{optionsSelect|json}} -->
