<ngx-smart-modal id="modal-to-drag" #reportBugModal [identifier]="'reportBugModal'">
  <div class="modal-header" id="mydivheader">
    <h4 class="modal-header__title">{{ 'DASHBOARD.reportBugModal.title' | translate }}</h4>
  </div>

  <div class="modal-body">
    <div class="container-fluid">
      <form class="form-inline form-horizontal row" novalidate>
        <div class="col-md-12">
          <div class="alert alert-danger" *ngIf="errorMessage">
            <ul>
              <li>{{ errorMessage }}</li>
            </ul>
          </div>
        </div>

        <div class="col-md-12">
          <div class="alert alert-danger" *ngIf="generalErrors.length">
            <ul>
              <li *ngFor="let error of generalErrors">{{ error }}</li>
            </ul>
          </div>
        </div>

        <div class="form-group col-md-12" [class.has-error]="errors?.title">
          <div class="container-fluid">
            <div class="row">
              <label class="col-md-4 control-label">{{
                'DASHBOARD.reportBugModal.fields.title.name' | translate
              }}</label>

              <div class="col-md-8">
                <input
                  type="text"
                  class="form-control"
                  name="title"
                  value=""
                  [ngModel]="bug.title | upperTransform"
                  (ngModelChange)="bug.title = $event"
                  placeholder="{{ 'DASHBOARD.reportBugModal.fields.title.placeholder' | translate }}"
                  required
                />
                <span *ngIf="errors.title" class="help-block">
                  {{ errors.title[0] }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group col-md-12" [class.has-error]="errors?.urgency">
          <div class="container-fluid">
            <div class="row">
              <label class="col-md-4 control-label">{{
                'DASHBOARD.reportBugModal.fields.urgency.name' | translate
              }}</label>
              <div class="col-md-8">
                <ng-select [clearable]="false" name="urgency" [(ngModel)]="bug.urgency" require>
                  <option value="critical">{{ 'DASHBOARD.reportBugModal.fields.urgency.critical' | translate }}</option>
                  <ng-option value="not_critical">
                    {{ 'DASHBOARD.reportBugModal.fields.urgency.not_critical' | translate }}
                  </ng-option>
                </ng-select>
                <span *ngIf="errors.urgency" class="help-block">
                  {{ errors.urgency[0] }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group col-md-12" [class.has-error]="errors?.screenshot">
          <div class="container-fluid">
            <div class="row">
              <label class="col-md-4 control-label">{{
                'DASHBOARD.reportBugModal.fields.screenshot.name' | translate
              }}</label>

              <div class="col-md-8">
                <input
                  id="screenshot"
                  type="file"
                  class="form-control"
                  name="screenshot"
                  (change)="uploadScreenShot($event)"
                  accept=".pdf,.jpg,.jpeg,.png,.bmp,.gif,.svg"
                  placeholder="{{ 'DASHBOARD.reportBugModal.fields.screenshot.placeholder' | translate }}"
                  required
                />
                <span *ngIf="errors.screenshot" class="help-block">
                  {{ errors.screenshot[0] }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group col-md-12" [class.has-error]="errors?.message">
          <div class="container-fluid">
            <div class="row">
              <label class="col-md-4 control-label">{{
                'DASHBOARD.reportBugModal.fields.message.placeholder' | translate
              }}</label>

              <div class="col-md-8">
                <textarea
                  type="text"
                  class="form-control"
                  name="message"
                  max="2048"
                  rows="5"
                  [ngModel]="bug.message | upperTransform"
                  (ngModelChange)="bug.message = $event"
                  placeholder="{{ 'DASHBOARD.reportBugModal.fields.message.placeholder' | translate }}"
                  required
                  style="max-width: 100%"
                >
                </textarea>
                <span *ngIf="errors.message" class="help-block">
                  {{ errors.message[0] }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-tertiary" (click)="reportBugModal.close()" data-dismiss="modal">
      {{ 'DASHBOARD.reportBugModal.actionButtons.cancel' | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="submit()" [disabled]="submitButtonDisabled">
      {{ 'DASHBOARD.reportBugModal.actionButtons.submit' | translate }}
    </button>
  </div>
</ngx-smart-modal>
