import { Component, ElementRef, OnInit } from '@angular/core';
import { HttpHelperService } from '../../@shared/services/httpHelper.service';
import { ContractsService } from '../../pages/contracts/contracts.service';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { getSideNavRoutes } from './side-nav.routes';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'leaso-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class SideNavComponent implements OnInit {
  isSideNavOpen = false;

  permissionsResult: any;

  showAdminMenuPermission: any;

  navs: any;

  active_tab: any;

  permittedModule: any;

  last_side_nav_stat: boolean;

  constructor(
    public translate: TranslateService,
    private httpHelperService: HttpHelperService,
    private contractsService: ContractsService,
    private router: Router,
    private _eref: ElementRef
  ) {
    this.permittedModule = JSON.parse(window.localStorage.getItem('permittedModule'));
    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.active_tab = event.url.replace('/', '');
      this.sideNavToggle(true);
    });
  }

  ngOnInit(): void {
    if (window.localStorage.getItem('user')) {
      this.checkPermissions();
    }
    this.contractsService.reloadCheckPermission$.subscribe((res: any) => {
      if (res) {
        this.checkPermissions();
      }
    });
    this.contractsService.loggedInUser$.subscribe((res: any) => {
      if (res) {
        this.checkPermissions();
      }
    });
  }

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target) && !event.target.classList.contains('fa')) {
      this.isSideNavOpen = false;
    }
  }

  checkPermissions() {
    let navLinks = [
      'MENU.CONTRACTS',
      'MENU.OFFERS',
      'MENU.CUSTOMERS',
      'MENU.management',
      'MENU.TRANSACTIONS',
      'MENU.CONTRACTTRANSACTION',
      'SUPPLIERS.view.title',
      'CustomerDetails.tabs.questionnaire.title',
      'MENU.USERS',
      'MENU.ORGANIZATIONS',
      'MENU.ROLES',
      'MENU.TYPES',
      'MENU.MAKES',
      'MENU.VEHICLES',
      'MENU.TASKS',
      'MENU.PRODUCTS',
      'MENU.PAYMENT_TERMS',
      'MENU.TRANSACTION_TYPES',
      'MENU.ECONOMICS',
      'MENU.AMORTIZATION',
    ];
    this.translate.get(navLinks).subscribe((translations) => {
      let payload = {
        permissions: [
          'view-organizations-data',
          'view-roles',
          'view-vehicle-types',
          'view-vehicle-brands',
          'view-vehicles',
          'view-tasks',
          'view-products',
          'view-payment-terms',
          'view-transaction-types',
          'view-leasing-contracts-payments-data',
          'view-leasing-contracts-transactions-data',
          'view-economic-entries-data',
          'view-contracts-amortization-matrix-data',
          'view-suppliers',
        ],
      };
      this.httpHelperService.checkPermissions(payload).subscribe((res: any) => {
        this.permissionsResult = res.data.permissionsResult;
        let index = Object.keys(this.permissionsResult).findIndex((key) => this.permissionsResult[key]);
        this.showAdminMenuPermission = index == -1 ? false : true;
        this.navs = getSideNavRoutes(this.permissionsResult, this.permittedModule);
      });
    });
  }

  onToggleSideNav() {
    this.isSideNavOpen = !this.isSideNavOpen;
  }

  onTabClick(tab, index) {
    if (tab.name !== 'management') {
      this.active_tab = tab.name;
    }

    if (!tab.route) {
      if (this.isSideNavOpen) {
        this.navs[index].is_sub_menu_opened = !this.navs[index].is_sub_menu_opened;
      } else {
        this.isSideNavOpen = true;
        this.navs[index].is_sub_menu_opened = true;
      }
    } else {
      this.router.navigate([tab.route]);
      this.isSideNavOpen = false;
    }
  }

  sideNavToggle(init = false) {
    if (!init) {
      this.isSideNavOpen = !this.isSideNavOpen;
    }
  }

  onSideNavMouseEnter(event: MouseEvent) {
    this.last_side_nav_stat = this.isSideNavOpen;
    this.isSideNavOpen = true;
  }

  onSideNavClick(event: MouseEvent) {
    this.last_side_nav_stat = this.isSideNavOpen;
  }

  onSideNavMouseLeave(event: MouseEvent) {
    this.isSideNavOpen = this.last_side_nav_stat;
  }
}
