import { AfterViewInit, Component, OnInit } from '@angular/core';
import { HttpHelperService } from '../../@shared/services/httpHelper.service';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';

import { DragHelper } from '../../@shared/drag-helper';

@Component({
  selector: 'leaso-report-bug-modal',
  templateUrl: './report-bug-modal.component.html',
  styleUrls: ['./report-bug-modal.component.scss'],
})
export class ReportBugModalComponent implements AfterViewInit {
  public errors: any = {};

  public generalErrors = [];

  public errorMessage;

  bug: any = {
    title: '',
    urgency: 'not_critical',
    message: '',
    screenshot: null,
  };

  submitButtonDisabled = false;

  constructor(private httpHelperService: HttpHelperService, private ngxSmartModalService: NgxSmartModalService) {}

  ngAfterViewInit(): void {
    this.ngxSmartModalService.getModal('reportBugModal').onOpenFinished.subscribe((modal: NgxSmartModalComponent) => {
      setTimeout(() => {
        new DragHelper().dragElement(document.querySelector('#modal-to-drag .nsm-dialog'));
      });
      // this.modal_data = modal.getData();
      // this.is_edit = this.modal_data.is_edit;
      // if (this.is_edit) {
      //   this.organization = JSON.parse(JSON.stringify(this.modal_data.data));
      //   this.getOrganizationDetails(this.organization.id);
      //   this.handleEdit();
      // } else {
      //   this.organization = {};
      // }
      this.onModalClose();
    });
  }

  uploadScreenShot(e) {
    this.bug.screenshot = e.target.files[0];
  }

  submit() {
    this.submitButtonDisabled = true;
    let formData: FormData = new FormData();
    Object.keys(this.bug).forEach((key) => {
      formData.append(key, this.bug[key]);
    });
    this.httpHelperService.reportBug(formData).subscribe(
      (res: any) => {
        this.submitButtonDisabled = false;
        this.httpHelperService.handleSuccess(res);
        this.ngxSmartModalService.getModal('reportBugModal').close();
      },
      (err) => {
        this.submitButtonDisabled = false;
        this.handleError(err);
      }
    );
  }

  handleError(err) {
    this.httpHelperService.handleError(err);
    if (err.status == 422) {
      this.errors = err?.error?.errors;
    }
  }

  onModalClose() {
    this.ngxSmartModalService.getModal('reportBugModal').onCloseFinished.subscribe((res: any) => {
      this.removeErrors();
    });
  }

  // This function is responsible for removing errors
  private removeErrors() {
    (document.getElementById('screenshot') as any).value = '';
    this.errorMessage = '';
    this.generalErrors = [];
    this.errors = {};
    this.bug = {
      title: '',
      urgency: 'not_critical',
      message: '',
      screenshot: null,
    };
  }
}
