export const getSideNavRoutes = (permissionsResult: any[], permittedModule: any) => {
  const baseMediaUrl = 'assets/media/nav';
  return [
    {
      name: 'contracts',
      label: 'MENU.CONTRACTS',
      active: false,
      has_children: false,
      children: [],
      icon: `${baseMediaUrl}/ic-contracts.svg`,
      route: '/contracts/leasing',
      hidden: false,
    },
    {
      name: 'deals',
      label: 'MENU.OFFERS',
      active: false,
      has_children: false,
      children: [],
      icon: `${baseMediaUrl}/ic-offers.svg`,
      route: '/deals',
      hidden: false,
    },
    {
      name: 'customers',
      label: 'MENU.CUSTOMERS',
      active: false,
      has_children: false,
      children: [],
      icon: `${baseMediaUrl}/ic-customers.svg`,
      route: '/customers',
      hidden: false,
    },
    {
      name: 'management',
      label: 'MENU.management',
      active: false,
      has_children: true,
      icon: `${baseMediaUrl}/ic-management.svg`,
      route: '',
      hidden: false,
      is_sub_menu_opened: false,
      children: [
        {
          name: 'payments',
          label: 'MENU.TRANSACTIONS',
          active: false,
          has_children: false,
          children: [],
          icon: `${baseMediaUrl}/ic-payments.svg`,
          route: '/admin/contracts/leasing/payments',
          hidden: !permissionsResult['view-leasing-contracts-payments-data'],
        },
        {
          name: 'transactions',
          label: 'MENU.CONTRACTTRANSACTION',
          active: false,
          has_children: false,
          children: [],
          icon: `${baseMediaUrl}/ic-transactions.svg`,
          route: '/admin/contracts/leasing/transactions',
          hidden: !permissionsResult['view-leasing-contracts-transactions-data'],
        },
        {
          name: 'suppliers',
          label: 'SUPPLIERS.view.title',
          active: false,
          route: '/suppliers',
          icon: `${baseMediaUrl}/ic-suppliers.svg`,
          hidden: !permissionsResult['view-suppliers'],
        },
        {
          name: 'kyc',
          label: 'CustomerDetails.tabs.questionnaire.title',
          active: false,
          route: '/kyc',
          icon: `${baseMediaUrl}/ic-kyc.svg`,
          hidden: permittedModule && !permittedModule['aml-feature'],
        },
        {
          name: 'users',
          label: 'MENU.USERS',
          active: false,
          has_children: false,
          children: [],
          icon: `${baseMediaUrl}/ic-users.svg`,
          route: '/users',
          hidden: false,
        },
        {
          name: 'organizations',
          label: 'MENU.ORGANIZATIONS',
          active: false,
          route: '/organizations',
          icon: `${baseMediaUrl}/ic-organizations.svg`,
          hidden: !permissionsResult['view-organizations-data'],
        },
        {
          name: 'roles',
          label: 'MENU.ROLES',
          active: false,
          route: '/roles',
          icon: `${baseMediaUrl}/ic-roles.svg`,
          hidden: !permissionsResult['view-roles'],
        },
        {
          name: 'vehicle-types',
          label: 'MENU.TYPES',
          active: false,
          route: '/vehicles/types',
          icon: `${baseMediaUrl}/ic-vehicle-types.svg`,
          hidden: !permissionsResult['view-vehicle-types'],
        },
        {
          name: 'vehicle-brands',
          label: 'MENU.MAKES',
          active: false,
          route: '/vehicles/brands',
          icon: `${baseMediaUrl}/ic-vehicle-brands.svg`,
          hidden: !permissionsResult['view-vehicle-brands'],
        },
        {
          name: 'vehicles',
          label: 'MENU.VEHICLES',
          active: false,
          route: '/vehicles',
          icon: `${baseMediaUrl}/ic-vehicles.svg`,
          hidden: !permissionsResult['view-vehicles'],
        },
        {
          name: 'tasks',
          label: 'MENU.TASKS',
          active: false,
          route: '/admin/contracts/tasks',
          icon: `${baseMediaUrl}/ic-tasks.svg`,
          hidden: !permissionsResult['view-tasks'],
        },
        {
          name: 'products',
          label: 'MENU.PRODUCTS',
          active: false,
          route: '/products',
          icon: `${baseMediaUrl}/ic-products.svg`,
          hidden: !permissionsResult['view-products'],
        },
        {
          name: 'payment-terms',
          label: 'MENU.PAYMENT_TERMS',
          active: false,
          route: '/payment/terms',
          icon: `${baseMediaUrl}/ic-payment-terms.svg`,
          hidden: !permissionsResult['view-payment-terms'],
        },
        {
          name: 'transaction-types',
          label: 'MENU.TRANSACTION_TYPES',
          active: false,
          route: '/transaction/types',
          icon: `${baseMediaUrl}/ic-transaction-types.svg`,
          hidden: !permissionsResult['view-transaction-types'],
        },
        {
          name: 'economic-entries-data',
          label: 'MENU.ECONOMICS',
          active: false,
          route: '/admin/econmic/entries',
          icon: `${baseMediaUrl}/ic-economic.svg`,
          hidden: !permissionsResult['view-economic-entries-data'],
        },
        {
          name: 'contracts-amortization-matrix-data',
          label: 'MENU.AMORTIZATION',
          active: false,
          route: '/admin/contracts',
          icon: `${baseMediaUrl}/ic-contracts-amortization.svg`,
          hidden: !permissionsResult['view-contracts-amortization-matrix-data'],
        },
      ],
    },
  ];
};
