<nav
  class="sidenav-container d-flex flex-column align-items-center bg-white pt-5 position-fixed"
  [ngClass]="{ 'sidenav-container--open': isSideNavOpen }"
  (mouseenter)="onSideNavMouseEnter($event)"
  (click)="onSideNavClick($event)"
  (mouseleave)="onSideNavMouseLeave($event)"
>
  <ng-container *ngFor="let navRoute of navs; let index = index">
    <div
      class="tab d-flex justify-content-center align-items-center cursor-pointer rounded-8"
      [hidden]="navRoute.permission"
      (click)="onTabClick(navRoute, index)"
      [ngClass]="{
        active: navRoute.name == active_tab || navRoute.route == '/' + active_tab,
        'cursor-default': navRoute.route == ''
      }"
    >
      <div class="icon-container d-flex justify-content-center align-items-center">
        <img [src]="navRoute.icon" [alt]="navRoute.label | translate" width="20px" height="20px" />
      </div>
      <span class="label text-sm text-uppercase" *ngIf="isSideNavOpen">{{ navRoute.label | translate }}</span>
      <span class="sub-menu-icon p-2 ml-auto" *ngIf="navRoute.has_children && isSideNavOpen">
        <i *ngIf="!navRoute.is_sub_menu_opened" class="fa fa-chevron-down" aria-hidden="true"></i>
        <i *ngIf="navRoute.is_sub_menu_opened" class="fa fa-chevron-up" aria-hidden="true"></i>
      </span>
    </div>
    <div class="sub-menu-container px-4 pb-5" *ngIf="isSideNavOpen && navRoute.is_sub_menu_opened">
      <div
        *ngFor="let sub of navRoute.children; let subIndex = index"
        [hidden]="sub.hidden"
        (click)="onTabClick(sub, subIndex)"
        class="sub-menu__item d-flex align-items-center cursor-pointer"
        [ngClass]="{
          active: sub.name == active_tab || sub.route == '/' + active_tab,
          'cursor-default': navRoute.route == ''
        }"
      >
        <img [src]="sub.icon" [alt]="sub.label | translate" width="18px" height="18px" />
        <span class="sub-label text-sm text-uppercase text-neutral-600 ml-2">{{ sub.label | translate }}</span>
      </div>
    </div>
  </ng-container>

  <div
    class="toggle-sidenav d-flex align-items-center justify-content-center bg-white position-absolute cursor-pointer"
    [ngClass]="{ 'toggle-sidenav--open': isSideNavOpen }"
    (click)="onToggleSideNav()"
  >
    <img src="assets/media/nav/ic-arrow.svg" alt="toggle sidenav" />
  </div>
</nav>
