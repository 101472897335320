import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

const DEFAULT_DISMISS_TIMER = 10000;

export interface Toast {
  type: 'info' | 'error' | 'warning' | 'success';
  title: string;
  description?: string;
  isClosed?: boolean;
  timer?: number;
  actions?: {
    content: string;
    onClick: (toast: Toast) => void;
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private $toasts = new Subject<Toast[]>();
  private toasts: Toast[] = [];

  public getAll() {
    return this.$toasts.asObservable();
  }

  public toast(toast: Toast): void {
    let is_duplicated_toast = this.toasts.find((saved_toast) => toast.title == saved_toast.title);
    if (is_duplicated_toast) return;
    else {
      this.toasts.push({ ...toast, isClosed: false, timer: toast?.timer || DEFAULT_DISMISS_TIMER });
      this.$toasts.next(this.toasts);
    }
  }

  public removeToast(index: number): void {
    setTimeout(() => {
      this.toasts.splice(index, 1);
    }, 280);
  }
}
