 <div
    (mouseenter)="onPauseTimer()"
    (mouseleave)="onResumeTimer()"
    class="toast-item"
    [ngClass]="toast.type"
    [class.closed]="toast.isClosed"
  >
    <div class="toast-header">
      <img [src]="'assets/media/toast/ic-' + toast?.type + '.svg'" width="20px" height="20px" [alt]="toast?.title" />
      <h6 class="toast-title">{{ toast?.title }}</h6>
      <span
        class="toast-collapse"
        *ngIf="toast?.description"
        (click)="isCollapsed = !isCollapsed"
        [ngClass]="{
          expand: !isCollapsed
        }"
      >
      </span>
      <span class="toast-dismiss" (click)="dismissToast()"> </span>
    </div>
    <div class="toast-body" *ngIf="!isCollapsed">
      <p class="toast-description">
        {{ toast?.description }}
      </p>
    </div>
    <div class="toast-footer" *ngIf="toast?.actions?.length">
      <a class="toast-action" *ngFor="let action of toast?.actions" (click)="toast?.onClick(toast)">{{
        action?.content
      }}</a>
    </div>
  </div>