import { AuthGuard } from './@core/auth/_guards/auth.guard';
import { AppComponent } from './app.component';
// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { importType } from '@angular/compiler/src/output/output_ast';

import { CustomPreloadingStrategyService } from './custom-preloading-strategy.service';

// Components
// Auth

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'organizations',
        loadChildren: () => import('./pages/organizations/organizations.module').then((m) => m.OrganizationsModule),
      },
      {
        path: 'suppliers',
        loadChildren: () => import('./pages/suppliers/suppliers.module').then((m) => m.SuppliersModule),
      },
      {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'roles',
        loadChildren: () => import('./pages/roles/roles.module').then((m) => m.RolesModule),
      },
      {
        path: 'vehicles',
        loadChildren: () => import('./pages/vehicles/vehicles.module').then((m) => m.VehiclesModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./pages/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'payment/terms',
        loadChildren: () => import('./pages/payment-terms/payment-terms.module').then((m) => m.PaymentTermsModule),
      },
      {
        path: 'password/change',
        loadChildren: () =>
          import('./pages/change-password/change-password.module').then((m) => m.ChangesPasswordModule),
      },
      {
        path: 'admin/econmic',
        loadChildren: () => import('./pages/econmic/econmic.module').then((m) => m.EconmicModule),
      },
      {
        path: 'admin/econmic/entries',
        loadChildren: () => import('./pages/econmic/econmic.module').then((m) => m.EconmicModule),
      },
      {
        path: 'admin/contracts/leasing/payments',
        loadChildren: () => import('./pages/payments/payments.module').then((m) => m.PaymentModule),
      },
      {
        path: 'admin/contracts',
        loadChildren: () => import('./pages/contracts/contracts.module').then((m) => m.ContractsModule),
      },
      {
        path: 'contracts',
        data: {
          preload: true,
          loadAfterSeconds: 1,
        },
        loadChildren: () => import('./pages/contracts/contracts.module').then((m) => m.ContractsModule),
      },
      {
        path: 'admin/contracts/leasing/transactions',
        loadChildren: () => import('./pages/transactions/transactions.module').then((m) => m.TransactionsModule),
      },
      {
        path: 'transaction',
        loadChildren: () => import('./pages/transactions/transactions.module').then((m) => m.TransactionsModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'products',
        loadChildren: () => import('./pages/products/products.module').then((m) => m.ProductsModule),
      },
      {
        path: 'customers',
        loadChildren: () => import('./pages/customers/customers.module').then((m) => m.CustomersModule),
      },
      {
        path: 'deals',
        loadChildren: () => import('./pages/deals/deals.module').then((m) => m.DealsModule),
      },
      {
        path: 'kyc',
        loadChildren: () => import('./pages/all-KYC/all-KYC.module').then((m) => m.AllKYCModule),
      },
      { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
      { path: '**', redirectTo: 'home', pathMatch: 'full' },
    ],
  },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: CustomPreloadingStrategyService })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
