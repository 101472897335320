<leaso-report-bug-modal></leaso-report-bug-modal>
<nav class="d-flex justify-content-between align-items-center bg-white px-6 rounded-8 my-5">
  <a (click)="goToPage('/home')"><img [src]="company_logo" alt="LEASO" height="25px" /></a>

  <div class="d-flex align-items-center dropdown">
    <button class="btn btn-secondary h-100" [routerLink]="['/deals/new']">NYT TILBUD</button>

    <button
      class="d-flex align-items-center ml-2 dropdown-trigger border-0 profile-menu"
      [matMenuTriggerFor]="profileMenu"
    >
      <img
        [src]="user?.data?.profile_picture_path"
        width="40px"
        height="40px"
        onerror="this.src='assets/media/common/user-placeholder.png'"
        class="current-pic"
        alt="profile picture"
      />

      <div class="d-flex align-items-start flex-column">
        <span>Hej</span>
        <div class="user-name">{{ user?.data?.first_name }} {{ user?.data?.last_name }}</div>
      </div>
    </button>
    <mat-menu #profileMenu="matMenu" xPosition="before">
      <button mat-menu-item class="dropdown-item" (click)="goToPage('/settings')">
        {{ 'MENU.SETTINGS' | translate }}
      </button>
      <button mat-menu-item class="dropdown-item" [routerLink]="['../../password/change']">
        {{ 'MENU.CHANGE_CODE' | translate }}
      </button>
      <button mat-menu-item class="dropdown-item" (click)="logout()">
        {{ 'MENU.LOGOUT' | translate }}
      </button>
      <p class="m-0 ls-font-size-3 px-3 pt-3 border-top pb-2">
        {{ appVersion }}
      </p>
    </mat-menu>
  </div>
</nav>
