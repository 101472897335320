// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../@core/reducers';
// Auth
import { AuthService } from '../../../@core/auth/_services/auth.service';
import { Login } from '../../../@core/auth/_actions/auth.actions';
import { AuthNoticeService } from '../../../@core/auth/auth-notice/auth-notice.service';
import { HttpHelperService } from '../../../@shared/services/httpHelper.service';
import { SlimLoadingBarService } from '@cime/ngx-slim-loading-bar';
import { ContractsService } from '../../../pages/contracts/contracts.service';
import { ValidationService } from '../../../@shared/services/validation.service';
import { ToastService } from '../../../@shared/components/toastr/toast.service';

/**
 * ! Just example => Should be removed in development
 */
const DEMO_PARAMS = {
  EMAIL: '',
  PASSWORD: '',
};

@Component({
  selector: 'leaso-kt-login',
  templateUrl: './login.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {
  // Public params
  loginForm: FormGroup;
  loading = false;
  isLoggedIn$: Observable<boolean>;
  errors: any = [];

  private unsubscribe: Subject<any>;

  private returnUrl: any;

  // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  /**
   * Component constructor
   *
   * @param router: Router
   * @param auth: AuthService
   * @param authNoticeService: AuthNoticeService
   * @param translate: TranslateService
   * @param store: Store<AppState>
   * @param fb: FormBuilder
   * @param cdr
   * @param route
   */
  constructor(
    private router: Router,
    private auth: AuthService,
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private httpHelperService: HttpHelperService,
    private slimLoadingBarService: SlimLoadingBarService,
    private toastService: ToastService,
    private contractsService: ContractsService
  ) {
    this.unsubscribe = new Subject();
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.auth.initializeCSRF().subscribe((res) => {});

    this.initLoginForm();

    // redirect back to the returnUrl before login
    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params.returnUrl || '/home';
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.authNoticeService.setNotice(null);
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
  }

  /**
   * Form initalization
   * Default params, validators
   */
  initLoginForm() {
    this.loginForm = this.fb.group({
      email: [
        DEMO_PARAMS.EMAIL,
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
        ]),
      ],
      password: [
        DEMO_PARAMS.PASSWORD,
        Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      ],
    });
  }

  /**
   * Form Submit
   */
  submit() {
    const controls = this.loginForm.controls;
    /** check form */
    if (this.loginForm.invalid) {
      Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
      return;
    }

    this.loading = true;

    const authData = {
      email: controls.email.value,
      password: controls.password.value,
    };
    this.slimLoadingBarService.start();
    const login$ =
      location.hostname == 'localhost'
        ? this.auth.createTestingToken(authData.email, authData.password)
        : this.auth.login(authData.email, authData.password);

    login$
      .pipe(
        tap(
          (user: any) => {
            if (user) {
              this.slimLoadingBarService.complete();
              // window.localStorage.setItem('user', JSON.stringify());
              this.store.dispatch(new Login({ authToken: user.data.access_token }));
              // this.router.navigateByUrl(this.returnUrl); // Main page
              // this.router.navigateByUrl('/home');
              this.httpHelperService.gettingAllPermissions().subscribe(
                (res: any) => {
                  let user = JSON.parse(window.localStorage.getItem('user'));

                  window.localStorage.setItem(`permissionsList`, JSON.stringify(res.data.permissionsResult));
                  this.router.navigateByUrl('/home');
                  this.contractsService.setLoggedInUser(true);
                },
                (err) => {}
              );
            } else {
              this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
            }
            this.httpHelperService.toggleHeader('presented');
          },
          (err) => {
            this.slimLoadingBarService.reset();
            if (err.status == 401) {
              this.toastService.toast({
                type: 'error',
                title: err.error.data.error,
              });
            }
            this.authNoticeService.setNotice(
              err.error.error || this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'),
              'danger'
            );
          }
        ),
        takeUntil(this.unsubscribe),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe();
  }

  isControlHasError(controlName: string) {
    return ValidationService.isControlHasError(this.loginForm, controlName);
  }
}
