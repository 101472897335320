import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { HttpHelperService } from '../../@shared/services/httpHelper.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Logout } from '../auth/_actions/auth.actions';
import { AppState } from '../reducers';
import { environment } from '../../../environments/environment';
import { ContractsService } from '../../pages/contracts/contracts.service';
import { UserService } from '../../pages/users/user.service';

@Component({
  selector: 'leaso-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  appVersion = environment.appVersion;
  appName: any;

  permissionsResult: any;

  user: any;

  showAdminMenuPermission = false;
  company_logo: any;

  constructor(
    private translate: TranslateService,
    private store: Store<AppState>,
    private httpHelperService: HttpHelperService,
    private router: Router,
    private ngxSmartModalService: NgxSmartModalService,
    private contractsService: ContractsService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.appName = environment.AppName;
    this.company_logo = window.localStorage.getItem('company_logo');
    this.checkPermisssions();
    if (window.localStorage.getItem('user')) {
      this.getLogo();
      this.user = JSON.parse(window.localStorage.getItem('user'));
      console.log(this.user);
    }
    this.contractsService.loggedInUser$.subscribe((res: any) => {
      this.getLogo();
      this.getUserData();
      this.user = JSON.parse(window.localStorage.getItem('user'));
      this.checkPermisssions();
    });
    this.contractsService.reloadCheckPermission$.subscribe((res: any) => {
      if (res) {
        this.checkPermisssions();
      }
    });
  }

  getLogo() {
    this.userService.getSessionTimeout().subscribe(
      (res: any) => {
        this.company_logo = res.data.company_logo;
        // window.localStorage.setItem('company_logo', res.data.company_logo);
      },
      (err) => {}
    );
  }

  getUserData() {
    this.httpHelperService.get('users/me').subscribe((res: any) => {
      this.user = res;
    });
  }

  checkPermisssions() {
    let payload = {
      permissions: [
        'view-organizations-data',
        'view-roles',
        'view-vehicle-types',
        'view-vehicle-brands',
        'view-vehicles',
        'view-tasks',
        'view-products',
        'view-payment-terms',
        'view-transaction-types',
        'view-leasing-contracts-payments-data',
        'view-leasing-contracts-transactions-data',
        'view-economic-entries-data',
        'view-contracts-amortization-matrix-data',
      ],
    };
    this.httpHelperService.checkPermissions(payload).subscribe(
      (res: any) => {
        this.permissionsResult = res.data.permissionsResult;
        let index = Object.keys(this.permissionsResult).findIndex((key) => this.permissionsResult[key]);
        this.showAdminMenuPermission = index == -1 ? false : true;
      },
      (err) => {
        // handle error
      }
    );
  }

  goToPage(page) {
    this.router.navigate([page]);
  }

  logout() {
    // localStorage.clear();
    this.httpHelperService.logout().subscribe(
      (res: any) => {
        window.localStorage.removeItem('user');
        window.localStorage.removeItem('leasoTokenKey');
        window.localStorage.removeItem('permissionsList');
        this.httpHelperService.toggleHeader('notPresented');
        this.router.navigateByUrl('auth/login');
      },
      (err) => {}
    );
  }

  openReportBugModal() {
    setTimeout(() => {
      this.ngxSmartModalService
        .getModal('reportBugModal')
        .setData(
          {
            is_edit: false,
            data: {},
          },
          true
        )
        .open();
    }, 0);
  }
}
