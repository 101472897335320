<div class="logo-leaso">
  <div class="col-12">
    <div class="col-3">
      <div class="kt-grid__item">
        <img src="./assets/media/logos/leaso-log.png" alt="" />
      </div>
    </div>
  </div>

  <div class="leaso-kt-login__body display-d">
    <div class="card leaso-kt-login__form">
      <div class="leaso-kt-login__title">
        <h3>{{ 'AUTH.LOGIN.TITLE' | translate }}</h3>
      </div>

      <!--begin::Form-->
      <form class="" [formGroup]="loginForm" autocomplete="off">
        <div class="form-group">
          <label>{{ 'AUTH.LOGIN.EMAIL' | translate }}</label>
          <input
            class="form-control"
            type="email"
            placeholder="{{ 'AUTH.LOGIN.EMAIL' | translate }}"
            formControlName="email"
            autocomplete="off"
            [ngClass]="{ 'form-control--invalid': isControlHasError('email') }"
          />
          <leaso-input-error [control]="loginForm.get('email')!"></leaso-input-error>
        </div>
        <div class="form-group">
          <label>{{ 'AUTH.LOGIN.PASSWORD' | translate }}</label>
          <input
            class="form-control"
            type="password"
            placeholder="{{ 'AUTH.LOGIN.PASSWORD' | translate }}"
            formControlName="password"
            autocomplete="off"
            [ngClass]="{ 'form-control--invalid': isControlHasError('password') }"
          />
          <leaso-input-error [control]="loginForm.get('password')!"></leaso-input-error>
        </div>
        <!--begin::Action-->
        <div class="leaso-kt-login__actions">
          <mat-checkbox name="remember_me">
            {{ 'AUTH.LOGIN.REMEMBER_ME' | translate }}
          </mat-checkbox>
          <button
            (click)="submit()"
            [ngClass]="{ 'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading }"
            id="kt_login_signin_submit"
            class="btn btn-primary"
          >
            {{ 'AUTH.LOGIN.BUTTON' | translate }}
          </button>
        </div>
        <!--end::Action-->
      </form>

      <div class="forgot-password">
        <h4 class="text-xl">{{ 'AUTH.GENERAL.FORGOT_PASSWORD' | translate }}</h4>
        <p class="text-base m-0 mb-3">
          {{ 'AUTH.LOGIN.FOOTER_TXT1' | translate }}
          <a href="javascript:;" routerLink="/auth/forgot-password" class="kt-link leaso-kt-login__link-forgot">
            {{ 'AUTH.GENERAL.HERE' | translate }}
          </a>
          {{ 'AUTH.LOGIN.FOOTER_TXT2' | translate }}
        </p>
      </div>
      <!--end::Form-->
    </div>
  </div>
</div>
