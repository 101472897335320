import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  exports: [MatIconModule],
})
export class SVGMaterialLoaderModule {
  customIcons: Array<[string, string]> = [
    ['ic-arrow', 'assets/media/icons/ic-arrow.svg'],
    ['ic-calendar', 'assets/media/icons/ic-calendar.svg'],
  ];
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    this.customIcons.forEach(([iconName, iconPath]) => {
      iconRegistry.addSvgIcon(iconName, sanitizer.bypassSecurityTrustResourceUrl(iconPath));
    });
  }
}
