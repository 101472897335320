import { CrudModule } from './crud/crud.module';
import { MatButtonModule } from '@angular/material/button';
import { AppRoutingModule } from './../app-routing.module';
import { Router } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
// Anglar
import { MissingTranslationStrategy, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Layout Directives
// Services
import { MatToolbarModule } from '@angular/material/toolbar';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HeaderComponent } from './header/header.component';
import { MatIcon } from '@angular/material/icon';
import { SideNavComponent } from './side-nav/side-nav.component';
import { MatListModule, MatNavList } from '@angular/material/list';
import { ReportBugModalComponent } from './report-bug-modal/report-bug-modal.component';
import { SharedModule } from '../@shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    AppRoutingModule,
    MatListModule,
    CrudModule,
    TranslateModule.forChild(),
    SharedModule,
  ],
  declarations: [
    //components
    HeaderComponent,
    SideNavComponent,
    ReportBugModalComponent,
  ],
  exports: [
    // directives
    TranslateModule,
    HeaderComponent,
    CrudModule,
    SideNavComponent,
    // pipes
  ],
  providers: [],
})
export class CoreModule {}
