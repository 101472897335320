import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'leaso-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
})
export class CustomSelectComponent implements AfterViewInit {
  @Input() optionsSelect = [];
  @Input() value = '';
  @Input() mapBind = '';
  @Input() propertyName = '';
  disabled = false;

  static nextId = 0;
  // custom input events
  events = [];
  @ViewChild('ngSelect') ngSelect: NgSelectComponent;
  constructor(private elRef: ElementRef, private renderer: Renderer2) {}
  ngAfterViewInit(): void {
    if (this.events) {
      this.events.forEach((el) => {
        if (el.eventName == 'change') {
          //   this.ngSelect.changeEvent.;
          if (typeof this.ngSelect !== 'undefined') {
            this.ngSelect.changeEvent.subscribe((data) => {
              // you should change any function to arrow function for working correct such as example at dataTable
              if (el['noNeedValue']) {
                if (el['parameter']) {
                  el.fn(el['parameter']);
                } else {
                  el.fn();
                }
              } else {
                if (el['parameter'] && el['parameter2']) {
                  el.fn(this.value, el['parameter'], el['parameter2']);
                } else if (el['parameter']) {
                  el.fn(this.value, el['parameter']);
                } else {
                  el.fn(this.value);
                }

                if (el.fn2) {
                  el.fn2();
                }
              }
            });
          }
        } else if (el.eventName == 'emit') {
          if (typeof this.ngSelect !== 'undefined') {
            this.ngSelect.changeEvent.subscribe((data) => {
              el.fn.emit();
            });
          }
        }
        //   this.elRef.nativeElement.querySelector('ng-select').addEventListener('change',
        //     alert('hello'));

        //   //   this.ngSelect.changeEvent.
      });
    }
  }

  @HostBinding()
  id = `custom-select-${CustomSelectComponent.nextId}`;
}
