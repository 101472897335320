import { ToastService } from './../components/toastr/toast.service';
import { Injectable } from '@angular/core';
import { API_URL, BASE_URL } from '../../@core/constants/api';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, observable, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class HttpHelperService {
  private api_url: String = API_URL;
  private _isHeaderPresented: BehaviorSubject<string> = new BehaviorSubject(null);
  public isHeaderPresented$: Observable<string> = this._isHeaderPresented.asObservable();

  constructor(
    private httpClient: HttpClient,
    private translate: TranslateService,
    private toastService: ToastService
  ) {}

  toggleHeader(value: string) {
    this._isHeaderPresented.next(value);
  }

  get(url, filters?): Observable<any> {
    if (filters) {
      return this.httpClient.get(`${this.api_url}/${url}`, { params: filters });
    } else {
      return this.httpClient.get(`${this.api_url}/${url}`);
    }
  }

  post(url, payload): Observable<any> {
    return this.httpClient.post(`${this.api_url}/${url}`, payload);
  }

  put(url, payload, headers?): Observable<any> {
    if (headers) {
      return this.httpClient.put(`${this.api_url}/${url}`, payload, {
        headers: headers,
      });
    } else {
      return this.httpClient.put(`${this.api_url}/${url}`, payload);
    }
  }

  patch(url, payload): Observable<any> {
    return this.httpClient.patch(`${this.api_url}/${url}`, payload);
  }

  delete(url): Observable<any> {
    return this.httpClient.delete(`${this.api_url}/${url}`);
  }

  downloadFile(url): any {
    return this.httpClient.get(`${this.api_url}/${url}`);
  }

  downloadFileAsBlob(url): any {
    return this.httpClient.get(`${this.api_url}/${url}`, {
      responseType: 'blob',
    });
  }

  downloadFileAsBlobPost(url): any {
    return this.httpClient.post(
      `${this.api_url}/${url}`,
      {},
      {
        responseType: 'blob',
      }
    );
  }

  downloadFilePost(url: any) {
    return this.httpClient.post(`${this.api_url}/${url}`, {});
  }

  checkPermissions(payload): any {
    // return this.httpClient.post(
    //   `${this.api_url}/users/permissions/check`,
    //   payload
    // );
    return new Observable((observer) => {
      if (!window.localStorage.getItem('permissionsList')) {
        observer.error();
        observer.complete();
      } else {
        let storedPermissionsList = JSON.parse(window.localStorage.getItem(`permissionsList`));
        let permissions = {};
        payload.permissions.forEach((permission) => {
          permissions[permission] = storedPermissionsList[permission];
        });
        for (let key in permissions) {
          if (permissions[key] == undefined || permissions[key] == false) {
            delete permissions[key];
          }
        }
        observer.next({
          data: {
            permissionsResult: permissions,
          },
        });
        observer.complete();
      }
    });
  }

  gettingAllPermissions(permissions?): any {
    if (permissions) {
      return this.httpClient.post(`${this.api_url}/users/permissions/check`, permissions);
    } else {
      let allPermissions: any = [
        'view-contracts-amortization-matrix-data',
        'create-contracts-amortization-matrix',
        'create-contracts-repayments-transactions',
        'view-asset-liability-types',
        'view-asset-liability-type',
        'add-asset-liability-type',
        'update-asset-liability-type',
        'view-leasing-contracts-payments-data',
        'book-leasing-contract-payments',
        'view-leasing-contracts-transactions-data',
        'book-leasing-contracts-transactions',
        'import-contract-transactions',
        'add-contract-customer-message',
        'view-contract-customer-messages',
        'view-contract-estimated-monthly-payments',
        'generate-draft-contract-pdf',
        'delete-draft-contract-pdf',
        'upload-contract-signed-pdf',
        'download-contract-signed-pdf',
        'view-realised-internal-rate-of-return',
        'import-contract-mileage',
        'download-contract-mileage-data-template',
        'view-contract-mileage-data',
        'add-leasing-contract-transaction',
        'view-leasing-contract-transactions-data',
        'import-cash-book-entries',
        'add-update-monthly-offer-contract-product',
        'update-contract',
        'view-leasing-contract-payments-data',
        'view-leasing-amortization-matrix',
        'view-tasks',
        'book-single-leasing-contract-payments',
        'add-customer',
        'view-customer',
        'update-customer',
        'view-customer-contracts-messages',
        'request-contract',
        'update-customer-invoices',
        'view-customers',
        'view-economic-entries-data',
        'update-economic-entries',
        'view-income-expense-types',
        'add-income-expense-type',
        'view-income-expense-type',
        'update-income-expense-type',
        'generate-depreciation-setting',
        'set-organization-depreciation-setting-as-default',
        'view-organizations-data',
        'view-organization',
        'add-organization',
        'update-organization',
        'view-payment-terms',
        'add-payment-term',
        'update-payment-term',
        'view-leasing-contracts-payments-data',
        'add-product',
        'update-product',
        'view-products',
        'view-product',
        'view-roles',
        'view-role',
        'add-role',
        'update-role',
        'delete-role',
        'view-settings',
        'add-setting',
        'update-setting',
        'manage-tasks-settings',
        'view-transaction-templates',
        'add-transaction-templates',
        'update-transaction-templates',
        'delete-transaction-templates',
        'add-offer-contract-cost-product',
        'view-transaction-types',
        'view-vehicle-brands',
        'view-vehicle-brand',
        'view-vehicle-types',
        'view-vehicle-type',
        'view-vehicles',
        'add-transaction-type',
        'update-transaction-type',
        'view-transaction-type',
        'view-users',
        'view-user',
        'add-user',
        'update-user',
        'add-vehicle-market-value',
        'view-vehicle-market-values',
        'update-vehicle',
        'add-vehicle-mileage',
        'view-vehicle-mileage',
        'view-vehicle-market-price-references',
        'download-vehicle-market-price-reference-document-file',
        'add-vehicle',
        'import-vehicles-market-value',
        'show-hide-vehicle-front-page',
        'add-vehicle-brand',
        'update-vehicle-brand',
        'add-vehicle-type',
        'update-vehicle-type',
        'download-transaction-document-file',
        'download-vehicles-market-value-template',
        'view-documents-on-customers',
        'download-documents-from-customers',
        'delete-documents-from-customers',
        'upload-documents-from-customers',
        'create-lookup',
        'edit-lookup',
        'delete-lookup',
        'view-questionnaire',
        'edit-questionnaire',
        'delete-questionnaire',
        'list-questionnaire',
        'view-questionnaire-expiration-date',
        'edit-questionnaire-expiration-date',
        'create-questionnaire-expiration-date',
        'view-questionnaire-switch-management',
        'edit-questionnaire-switch-management',
        'view-questionnaire-aml-approval',
        'edit-questionnaire-aml-approval',
        'create-customer-questionnaire',
        'view-customer-questionnaire',
        'edit-customer-questionnaire',
        'delete-customer-questionnaire',
        'list-customer-questionnaire',
        'list-leasing-types',
        'update-leasing-type',
        'copy-customer-questionnaire',
        'lock-customer-questionnaire',
        'view-suppliers',
        'add-suppliers',
        'edit-suppliers',
        'update-suppliers',
        'delete-leasing-contract-payments-from-contract',
      ];
      let payload = {
        permissions: allPermissions,
      };
      return this.httpClient.post(`${this.api_url}/users/permissions/check`, payload);
    }
  }

  handleSuccess(res, message = null, timer = 2000) {
    if (message) {
      this.toastService.toast({
        type: 'success',
        title: message,
        timer: timer,
      });
      return;
    } else {
      this.toastService.toast({
        type: 'success',
        title: res?.data?.message || res?.message,
        timer: timer,
      });
    }
  }

  handleError(err, msg?, timer = 2000) {
    if (msg) {
      this.toastService.toast({
        type: 'error',
        title: msg,
        timer: timer,
      });
      return;
    }
    if (err.status == 422) {
      this.toastService.toast({
        type: 'error',
        title: err?.error?.message || err?.error?.data?.message,
        timer: timer,
      });
    } else if (err.status == 500) {
      this.toastService.toast({
        type: 'error',
        title: 'Internal Server Error',
        timer: timer,
      });
    } else if (err.status == 400) {
      this.toastService.toast({
        type: 'error',
        title: err?.error?.data?.message || err?.error?.message,
        timer: timer,
      });
    } else if (err.status == 404) {
      this.toastService.toast({
        type: 'error',
        title: err?.error?.data?.message || 'error',
        timer: timer,
      });
      if (err?.error?.data?.message instanceof Array && err?.error?.data?.message.length > 0) {
        this.toastService.toast({
          type: 'error',
          title: err?.error?.data?.message[0],
          timer: timer,
        });
      } else {
        this.toastService.toast({
          type: 'error',
          title: err?.error?.data?.message,
          timer: timer,
        });
      }
    } else if (err.status == 403) {
      this.toastService.toast({
        type: 'error',
        title: err?.error?.message,
        timer: timer,
      });
    } else {
      this.toastService.toast({
        type: 'error',
        title: 'Server Interal Error, please contact support',
        timer: timer,
      });
    }
  }

  showWarning(msg: string, timer = 2000) {
    this.toastService.toast({
      type: 'warning',
      title: msg,
      timer: timer,
    });
  }

  reportBug(payload) {
    return this.httpClient.post(`${this.api_url}/bugs`, payload);
  }

  logout() {
    return this.httpClient.post(`${this.api_url}/auth/logout`, {});
  }

  getDropZoneMessage(str) {
    return `
      <div class='d-flex flex-column align-items-center justify-content-center'>
        <img src="assets/media/icons/ic-upload.svg" alt="upload" class="mb-1"/>
        <span class="text-primary-500">
            ${this.translate.instant(str)}
        </span>
      </div>`;
  }
}
