/* This class is responsible for Dragging modal functionality */

export class DragHelper {
  // Drag method
  public dragElement(element: any): void {
    var pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;

    if (document.getElementById('mydivheader')) {
      /* if present, the header is where you move the DIV from:*/
      document.getElementById('mydivheader').onmousedown = dragMouseDown;
    } else {
      /* otherwise, move the DIV from anywhere inside the DIV:*/
      element.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
      console.log('mousedown');
      console.log(e.clientX, element.offsetLeft);
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
      console.log('element drag', e.clientX);
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;

      // set the element's new position:
      if (!element.style.left) {
        element.style.position = 'absolute';
        element.style.top = element.offsetTop - pos2 + 'px';
        element.style.left = window.innerWidth / 2 - element.clientWidth / 2 + 'px';
      } else {
        element.style.position = 'absolute';
        element.style.top = element.offsetTop - pos2 + 'px';
        element.style.left = element.offsetLeft - pos1 + 'px';
      }
    }

    function closeDragElement() {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }
}
