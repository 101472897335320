<div class="logo-leaso">
  <div class="col-12">
    <div class="col-3">
      <div class="kt-grid__item">
        <img src="./assets/media/logos/leaso-log.png" alt="" />
      </div>
    </div>
  </div>

  <!--begin::Body-->
  <div class="leaso-kt-login__body">
    <!--begin::Signin-->
    <div class="card kt-login__form">
      <div class="kt-login__title">
        <h3>{{ 'AUTH.LOGIN.TITLE' | translate }}</h3>
      </div>

      <leaso-leaso-kt-auth-notice></leaso-leaso-kt-auth-notice>

      <form class="kt-form mt-3" [formGroup]="forgotPasswordForm" autocomplete="off">
        <div class="form-group mb-5">
          <input
            type="email"
            placeholder="{{ 'AUTH.LOGIN.EMAIL' | translate }}"
            formControlName="email"
            autocomplete="off"
            class="form-control"
          />
          <mat-error *ngIf="errors?.email">
            <strong>{{ errors?.email[0] }}</strong>
          </mat-error>
        </div>
        <!--begin::Action-->
        <div class="kt-login__actions border-0">
          <button
            (click)="submit()"
            id="kt_login_signin_submit"
            class="btn btn-primary w-100"
            [ngClass]="{ 'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading }"
          >
            {{ 'AUTH.FORGOT.FORGOT_BUTTON' | translate }}
          </button>
        </div>
        <!--end::Action-->
      </form>
    </div>
  </div>
</div>
