import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpHelperService } from '../../@shared/services/httpHelper.service';
import { BehaviorSubject, observable, Observable, Observer } from 'rxjs';
import Swal from 'sweetalert2';
import { API_URL, BASE_URL } from '../../@core/constants/api';
import { ContractTask } from '../../@shared/models/contract-task.model';
import {
  ExpectationRegisterationPayload,
  ExpectationRegisterationResponse,
} from '../../@shared/models/copy-contract.model';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ContractsService {
  private api_url: String = API_URL;
  private base_url: String = BASE_URL;
  private _addedNewPayment: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public addedNewPayment$: Observable<boolean> = this._addedNewPayment.asObservable();
  private _addedNewNote: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public addedNewNote$: Observable<boolean> = this._addedNewNote.asObservable();

  private _addedTransaction: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public addedTransaction$: Observable<boolean> = this._addedTransaction.asObservable();

  private _updatedCustomerInfo: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public updateCustomerInfo$: Observable<any> = this._updatedCustomerInfo.asObservable();

  private _updatedContractTask: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public updateContractTask$: Observable<any> = this._updatedContractTask.asObservable();

  private _updatedVehiclePlate: BehaviorSubject<any> = new BehaviorSubject(null);
  public updateVehiclePlate$: Observable<any> = this._updatedVehiclePlate.asObservable();

  private _updateVehicleDetails: BehaviorSubject<any> = new BehaviorSubject(null);
  public updateVehicleDetails$: Observable<any> = this._updateVehicleDetails.asObservable();

  private _updateCustomer: BehaviorSubject<any> = new BehaviorSubject(null);
  public updateCustomer$: Observable<any> = this._updateCustomer.asObservable();

  private _nextTab: BehaviorSubject<any> = new BehaviorSubject(null);
  public nextTab$: Observable<any> = this._nextTab.asObservable();

  private _backTab: BehaviorSubject<any> = new BehaviorSubject(null);
  public backTab$: Observable<any> = this._backTab.asObservable();

  private _addProduct: BehaviorSubject<any> = new BehaviorSubject(null);
  public addProduct$: Observable<any> = this._addProduct.asObservable();

  private _onCalculation: BehaviorSubject<any> = new BehaviorSubject(null);
  public onCalculation$: Observable<any> = this._onCalculation.asObservable();

  lastCalculation: any;

  private _onLeasingTypechange: BehaviorSubject<any> = new BehaviorSubject(null);
  public onLeasingTypechange$: Observable<any> = this._onLeasingTypechange.asObservable();

  private _onContractTypechange: BehaviorSubject<any> = new BehaviorSubject(null);
  public onContrractTypechange$: Observable<any> = this._onContractTypechange.asObservable();

  private _prepareVehicleInfoTab: BehaviorSubject<any> = new BehaviorSubject(null);
  public prepareVehicleInfoTab$: Observable<any> = this._prepareVehicleInfoTab.asObservable();

  private _newContractAdded: BehaviorSubject<any> = new BehaviorSubject(null);
  public newContractAdded$: Observable<any> = this._newContractAdded.asObservable();

  private _onTaskfinished: BehaviorSubject<any> = new BehaviorSubject(null);
  public onTaskfinished$: Observable<any> = this._onTaskfinished.asObservable();

  private _onLeasingDestory: BehaviorSubject<any> = new BehaviorSubject(null);
  public onLeasingDestory$: Observable<any> = this._onLeasingDestory.asObservable();

  private _prepareDealsCustomerTab: BehaviorSubject<any> = new BehaviorSubject(null);
  public prepareDealsCustomerTab$: Observable<any> = this._prepareDealsCustomerTab.asObservable();

  private _canCompleteTask: BehaviorSubject<ContractTask> = new BehaviorSubject(null);
  public canCompleteTask$: Observable<ContractTask> = this._canCompleteTask.asObservable();

  vehicle: any;
  lease: any;
  selectedLeasingType: any;
  selectedContractType: any;
  products: any;

  newCustomerId: any;
  newLeasingContractId: any;
  // isleasingCalculated: boolean = false;
  newCompanyId: any;

  company_select_type: any;
  secondary_split_leasing_customer: any;

  private _firstStepCalculationsResult: BehaviorSubject<any> = new BehaviorSubject(null);
  public firstStepCalculationsResult$: Observable<any> = this._firstStepCalculationsResult.asObservable();

  private _costProductAdded: BehaviorSubject<any> = new BehaviorSubject(null);
  public costProductAdded$: Observable<any> = this._costProductAdded.asObservable();

  private _pageNumberBookedInvoicesFilters: BehaviorSubject<any> = new BehaviorSubject(null);
  public pageNumberBookedInvoicesFilters$: Observable<any> = this._pageNumberBookedInvoicesFilters.asObservable();

  private _productCheckboxToggled: BehaviorSubject<any> = new BehaviorSubject(null);
  public productCheckboxToggled$: Observable<any> = this._productCheckboxToggled.asObservable();

  private _resetStepper: BehaviorSubject<any> = new BehaviorSubject(null);
  public resetStepper$: Observable<any> = this._resetStepper.asObservable();

  private _loggedInUser: BehaviorSubject<any> = new BehaviorSubject(null);
  public loggedInUser$: Observable<any> = this._loggedInUser.asObservable();

  private _resetLeasingCalc: BehaviorSubject<any> = new BehaviorSubject(null);
  public resetLeasingCalc$: Observable<any> = this._resetLeasingCalc.asObservable();

  private _reloadCheckPermission: BehaviorSubject<any> = new BehaviorSubject(null);
  public reloadCheckPermission$: Observable<any> = this._reloadCheckPermission.asObservable();

  private _clearVehicleInfo: BehaviorSubject<any> = new BehaviorSubject(null);
  public clearVehicleInfo$: Observable<any> = this._clearVehicleInfo.asObservable();

  private _clearCustomerInfo: BehaviorSubject<any> = new BehaviorSubject(null);
  public clearCustomerInfo$: Observable<any> = this._clearCustomerInfo.asObservable();

  private _startSearchForVehicles: BehaviorSubject<any> = new BehaviorSubject(null);
  public startSearchForVehicles$: Observable<any> = this._startSearchForVehicles.asObservable();

  private _selectedVehicle: BehaviorSubject<any> = new BehaviorSubject(null);
  public selectedVehicle$: Observable<any> = this._selectedVehicle.asObservable();

  private _revertCalculationResult: BehaviorSubject<any> = new BehaviorSubject(null);
  public revertCalculationResult$: Observable<any> = this._revertCalculationResult.asObservable();

  private _stepperChanged: BehaviorSubject<any> = new BehaviorSubject(null);
  public stepperChanged$: Observable<any> = this._stepperChanged.asObservable();

  private _updateFromCompanyToSplit: BehaviorSubject<any> = new BehaviorSubject(null);
  public updateFromCompanyToSplit$: Observable<any> = this._stepperChanged.asObservable();

  private _newCustomerSelected: BehaviorSubject<any> = new BehaviorSubject(null);
  public newCustomerselected$: Observable<any> = this._newCustomerSelected.asObservable();

  private _uploadedContract: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public uploadedContract: Observable<boolean> = this._uploadedContract.asObservable();

  vehicle_type: any;

  current_selected_customer;
  any;

  constructor(
    private httpHelperService: HttpHelperService,
    private httpClient: HttpClient,
    private translate: TranslateService,
    private datepipe: DatePipe
  ) {}

  /**
   * @description emit contractTask on user allow task action
   */
  setCanCompleteContractTask(value: ContractTask) {
    this._canCompleteTask.next(value);
  }

  setUploadContractModal(value: boolean) {
    this._uploadedContract.next(value);
  }

  setNewCustomerSelected(value: any) {
    this._newCustomerSelected.next(value);
  }

  updatefromCommpanyToSplit(value: any) {
    this._updateFromCompanyToSplit.next(value);
  }

  prepareDealsCustomerTab(value: any) {
    this._prepareDealsCustomerTab.next(value);
  }

  setStepperChanged(value: any) {
    this._stepperChanged.next(value);
  }

  setRevertCalculationResult(value: any) {
    this._revertCalculationResult.next(value);
  }

  setSelectedVehicle(value: any) {
    this._selectedVehicle.next(value);
  }

  setStartSearchForVehicles(value: any) {
    this._startSearchForVehicles.next(value);
  }

  setClearCustomerInfo(value: any) {
    this._clearCustomerInfo.next(value);
  }

  setClearVehicleInfo(value: any) {
    this._clearVehicleInfo.next(value);
  }

  setReloadCheckPermission(value: any) {
    this._reloadCheckPermission.next(value);
  }

  resetLeasingCalc(value: any) {
    this._resetLeasingCalc.next(value);
  }

  setLoggedInUser(value: any) {
    this._loggedInUser.next(value);
  }

  resetStepper(value: any) {
    this._resetStepper.next(value);
  }

  setProductCheckboxToggled(value: any) {
    this._productCheckboxToggled.next(value);
  }

  resetPageNumberBookedInvoicesFilters(value: any) {
    this._pageNumberBookedInvoicesFilters.next(value);
  }

  setCostProductAdded(value: any) {
    this._costProductAdded.next(value);
  }

  setDestroyLeasingComponent(value: boolean) {
    this._onLeasingDestory.next(value);
  }

  setFirstStepCalculationsResult(value: any) {
    this._firstStepCalculationsResult.next(value);
  }

  setTaskfinished(value: any) {
    this._onTaskfinished.next(value);
  }

  setNewContractAdded(value: any) {
    this._newContractAdded.next(value);
  }

  setPrepareVehicleInfoTab(value: any) {
    this._prepareVehicleInfoTab.next(value);
  }

  setLesingTypeChanged(value: any) {
    this._onLeasingTypechange.next(value);
  }

  setContractTypeChanged(value: any) {
    this._onContractTypechange.next(value);
  }

  setCalculationsChanged(value: any) {
    this.lastCalculation = value;
    this._onCalculation.next(value);
  }

  setAddedProduct(value: any) {
    this._addProduct.next(value);
  }

  setBackTab(value: any) {
    this._backTab.next(value);
  }

  setNextTab(value: any) {
    this._nextTab.next(value);
  }

  setNewPaymentHasBeenAdded(value: boolean) {
    this._addedNewPayment.next(value);
  }

  setNewNoteHasBeenAdded(value: boolean) {
    this._addedNewNote.next(value);
  }

  setNewTransactionHasBeenAdded(value: boolean) {
    this._addedTransaction.next(value);
  }

  updateContractTasks(value: boolean) {
    this._updatedContractTask.next(value);
  }

  setUpdateCustomer(value: any) {
    this._updateCustomer.next(value);
  }

  setUpdateCustomerInfo(value: boolean) {
    this._updatedCustomerInfo.next(value);
  }

  setUpdateVehiclePlate(value: any) {
    this._updatedVehiclePlate.next(value);
  }

  setUpdateVehicleDetails(value: any) {
    this._updateVehicleDetails.next(value);
  }

  createAmortizations() {
    return this.httpHelperService.post('admin/contracts/amortization-matrix', {});
  }

  createRepaymentTransactions() {
    return this.httpHelperService.post(`admin/contracts/repayment-transactions`, {});
  }

  getAmortization(type, filters) {
    return this.httpHelperService.get(`admin/contracts/amortization-matrix/data/${type}`, filters);
  }

  getTasks(type, filters = {}) {
    return this.httpHelperService.get(`admin/contracts/tasks-data/${type}`, filters);
  }

  markTaskAsDone(customerId, leasingContractId, payload) {
    return this.httpHelperService.post(`tasks/contract/leasing/${customerId}/${leasingContractId}/complete`, payload);
  }

  markTaskAsNotDone(customerId, leasingContractId, payload) {
    return this.httpHelperService.post(`tasks/contract/leasing/${customerId}/${leasingContractId}/incomplete`, payload);
  }

  getTasksForEdit() {
    return this.httpHelperService.get(`tasks/list`);
  }

  getTaskInfo(customerId, contractId, contractTaskId) {
    return this.httpHelperService.get(`tasks/contract/leasing/${customerId}/${contractId}/edit/${contractTaskId}`);
  }

  editTask(customerId, leasingContractId, contractTaskId, payload) {
    return this.httpHelperService.put(
      `contract/leasing/${customerId}/${leasingContractId}/edit/${contractTaskId}`,
      payload
    );
  }

  getLeasing(type, filters) {
    return this.httpHelperService.get(`contracts/leasing/data/all/${type}`, filters);
  }

  getContractStatus() {
    return this.httpHelperService.get(`contract/statuses`);
  }

  getContractDetails(customerID, leasingContractId) {
    return this.httpHelperService.get(`contract/leasing/${customerID}/${leasingContractId}/details`);
  }

  getContract(leasingContractId, customerID) {
    return this.httpHelperService.get(`contract/leasing/${leasingContractId}/${customerID}`);
  }

  getContractCustomers(contractId) {
    return this.httpHelperService.get(`contract/leasing/customers/${contractId}`);
  }

  getProductPaymentVat(paymentId, paymentExclTaxVat) {
    return this.httpHelperService.post(`contract/payment/vat/${paymentId}`, {
      payment_excl_tax_vat: paymentExclTaxVat,
    });
  }

  getPaymentTypeDefaultDetails(type: number) {
    return this.httpHelperService.get(`products/info/${type}`);
  }

  addPayment(contractId, payload) {
    return this.httpHelperService.post(`contract/leasing/${contractId}/payment/add`, payload);
  }

  deletePayment(contractId, paymentId) {
    return this.httpHelperService.delete(`admin/contracts/leasing/${contractId}/payments/${paymentId}`);
  }

  generateSplitLeasingPayment(contractId, payment) {
    return this.httpHelperService.post(`contract/split-leasing/${contractId}/cashflow`, payment);
  }

  generatePayment(contractId, payment) {
    return this.httpHelperService.post(`contract/leasing/${contractId}/cashflow`, payment);
  }

  generateContractPayment(contractId, customerId, payment) {
    return this.httpHelperService.post(`tasks/contract/leasing/${customerId}/${contractId}/monthly-payments`, payment);
  }

  saveInvoice(ContractId, payload) {
    return this.httpHelperService.post(`contract/leasing/${ContractId}/invoice`, payload);
  }

  getCustomerContractMessagesData(contractId, filters = {}) {
    return this.httpHelperService.get(
      `contract/leasing/customer/messages/data/datatable?contract_id=${contractId}`,
      filters
    );
  }

  addContractNote(payload) {
    return this.httpHelperService.post(`contract/leasing/customer-message/add`, payload);
  }

  runInterestMonthly(contractId) {
    return this.httpHelperService.post(`contract/transactions/run-monthly-interest/${contractId}`, {});
  }

  closeContract(customerId, contractId) {
    return this.httpHelperService.get(`tasks/contract/leasing/canceled/${customerId}/${contractId}`);
  }

  getContractEstimatedMothlyPaymentData(contractId, type, filters = {}) {
    return this.httpHelperService.get(
      `contract/leasing/${contractId}/estimated-monthly-payments/data/${type}`,
      filters
    );
  }

  postAmortizations(contractId) {
    return this.httpHelperService.post(`contract/amortization-matrix/${contractId}`, {});
  }

  postRepaymentTransactions(contractId) {
    return this.httpHelperService.post(`contract/repayment-transactions/${contractId}`, {});
  }

  changeContractCustomer(contractId, customerId, payload) {
    return this.httpHelperService.post(`contract/leasing/${contractId}/${customerId}`, payload);
  }

  getContractTasks(type, filters = {}) {
    return this.httpHelperService.get(`admin/contracts/tasks-data/${type}`, filters);
  }

  updateTask(customerId, contractId, taskId, payload) {
    return this.httpHelperService.put(`tasks/contract/leasing/${customerId}/${contractId}/update/${taskId}`, payload);
  }

  addTask(customerId, contractId, payload) {
    return this.httpHelperService.post(`tasks/contract/leasing/${customerId}/${contractId}/store`, payload);
  }

  getCustomFields(customerId, contractId) {
    return this.httpHelperService.get(`contract/custom-fields/${customerId}/${contractId}`);
  }

  updateCustomFields(customerId, contractId, paylod) {
    return this.httpHelperService.put(`contract/custom-fields/${customerId}/${contractId}`, paylod);
  }

  uploadContractSignedDocument(customerId, contractId, payload) {
    return this.httpHelperService.post(`contract/signed-document/upload/${customerId}/${contractId}`, payload);
  }

  deleteContractDraft(customerId, contractId) {
    return this.httpHelperService.delete(`contract/leasing/${customerId}/${contractId}/contract-pdf/delete-draft`);
  }

  downloadSignedContractPDf(customerId, contractId) {
    return this.httpHelperService.downloadFile(
      `contract/leasing/${customerId}/${contractId}/contract-pdf/download-signed`
    );
  }

  downloadDraftContractPDf(customerId, contractId) {
    return this.httpHelperService.downloadFilePost(
      `contract/leasing/${customerId}/${contractId}/contract-pdf/download-draft`
    );
  }

  updateVehiclePlateNumber(contractId, payload) {
    return this.httpHelperService.put(`contract/leasing/${contractId}/updateVehiclePlateNumber`, payload);
  }

  updateVehicleInContract(contractId, payload) {
    return this.httpHelperService.put(`contract/leasing/${contractId}/change-vehicle`, payload);
  }

  getContractMileageSplit(id, dateOfStatement) {
    return this.httpHelperService.get(`contract/leasing/${id}/mileage-split?date_of_statement=${dateOfStatement}`);
  }

  createSplitPayments(contractId, dateOfStatement) {
    return this.httpHelperService.post(`contract/leasing/${contractId}/mileage-split`, {
      date_of_statement: dateOfStatement,
    });
  }

  getInternalRate(contractId) {
    return this.httpHelperService.get(`contract/leasing/${contractId}/internalRate`);
  }

  getContractMileageData(contractId, filters = {}) {
    return this.httpHelperService.get(`contract/leasing/${contractId}/mileage/data`, filters);
  }

  changeSecondaryCustomer(contractId, data) {
    return this.httpHelperService.post(`contract/leasing/${contractId}/customer/secondary`, data);
  }

  changeGuarantorCustomer(contractId, data) {
    return this.httpHelperService.post(`contract/leasing/${contractId}/customer/guarantor`, data);
  }

  getCalculatorStepOne(payload) {
    return this.httpHelperService.post(`vehicle/marketPriceReference/calculations`, payload);
  }

  checkModuleFields(category: string, module_fields: any[]) {
    let body = {
      category: category,
      module_fields: module_fields,
    };
    return this.httpHelperService.post(`users/module-fields/check`, body);
  }

  //This function is responsible for getting the new calculation from server
  calculate(lease, leasingType): Observable<{}> {
    if (leasingType == 'deal') {
      leasingType = 'offer';
    }
    for (let key in lease) {
      let item = lease[key];
      // if (key != 'expected_payout_date' && key != 'sent_for_approval_date') {
      //   if (item == null) {
      //     // delete lease[key];
      //     lease[key] = 0;
      //   }
      // }
      if (key == 'expected_payout_date') {
        if (item == null) {
          // delete lease[key];
          lease[key] = '';
        }
      }
    }
    console.log(lease.kilometer_limit);
    if (lease.kilometer_limit) lease.kilometer_limit = lease?.kilometer_limit?.toString().replaceAll('.', '');
    return this.httpHelperService.post(`${leasingType}/calculation`, lease);
  }

  calculateDealSecondStep(payload) {
    return this.httpHelperService.post(`deal/calculation/confirm`, payload);
  }

  addProduct(product, type) {
    if (type == 'deal' || type == 'offer') {
      return this.httpHelperService.post(`offer/product/row`, product);
    } else if (type == 'contract') {
      return this.httpHelperService.post(`contract/product/row`, product);
    }
  }

  addProductToOffer(offerId, product, type) {
    if (type == 'deal') {
      return this.httpHelperService.post(`leasing/${offerId}/offer/product`, product);
    } else if (type == 'contract') {
      return this.httpHelperService.post(`leasing/${offerId}/contract/product`, product);
    }
  }

  // For Adding product in edit page (On check product):
  addProductFromLeasingCalculator(moduleName, leasingId, monthlyProductId, data) {
    return this.httpHelperService.post(
      `offer/contract/${leasingId}/monthlyProduct/${monthlyProductId}/${moduleName}`,
      data
    );
  }

  // For Delete product in edit page (On uncheck product):
  removeProductFromLeasingCalculator(moduleName, leasingId, monthlyProductId) {
    return this.httpClient.delete(`offer/contract/${leasingId}/monthlyProduct/${monthlyProductId}/${moduleName}`);
  }

  addContract(payload) {
    if (!payload.customer_id) {
      payload.customer_id = payload.customerId;
    }
    return this.httpHelperService.post(`contract/leasing/new`, payload);
  }

  getContractDetailsTasks(customerId, contractId) {
    return this.httpHelperService.get(`tasks/contract/leasing/${customerId}/${contractId}`);
  }

  financialInfoSummary(customerId, date) {
    return this.httpHelperService.get(`customer/${customerId}/financial-info/summary?date=${date}`);
  }

  financialInfoAvailableDates(customerId) {
    return this.httpHelperService.get(`customer/${customerId}/financial-info/available-dates`);
  }

  getTaskMessage(customerId, contractId, taskCode) {
    return this.httpHelperService.get(
      `tasks/contract/leasing/${customerId}/${contractId}/message?contract_task_code=${taskCode}`
    );
  }

  //This function is responsible for adding new approve
  addApprove(customerId, contractId, approve) {
    return this.httpHelperService.post(
      `tasks/contract/leasing/credit-approvement/${customerId}/${contractId}`,
      approve
    );
  }

  createUpfrontPayments(customerId, contractId, payload) {
    console.log('entered here');
    return this.httpHelperService.post(`tasks/contract/leasing/${customerId}/${contractId}/upfront-payments`, payload);
  }

  updateContract(leasingContractId, payload) {
    if (payload.vehicle_mileage) payload.vehicle_mileage = payload.vehicle_mileage?.toString().replaceAll('.', '');
    if (payload.kilometer_limit) payload.kilometer_limit = payload.kilometer_limit?.toString().replaceAll('.', '');
    return this.httpHelperService.put(`contract/leasing/update/${leasingContractId}`, payload);
  }

  updateDeal(id, payload) {
    if (payload.vehicle_mileage) payload.vehicle_mileage = payload.vehicle_mileage?.toString().replaceAll('.', '');
    if (payload.kilometer_limit) payload.kilometer_limit = payload.kilometer_limit?.toString().replaceAll('.', '');
    return this.httpHelperService.put(`deal/update/${id}`, payload);
  }

  completeTask(customerId, leasingContractId, payload) {
    return this.httpHelperService.post(`tasks/contract/leasing/${customerId}/${leasingContractId}/complete`, payload);
  }

  getCalculations(customerId, contractId) {
    return this.httpHelperService.get(` `);
  }

  otherLeasingContractsWithSameVehicle(vehicleId) {
    return this.httpHelperService.get(`contracts/leasing/${vehicleId}`);
  }

  cancelContractStatus(customerId, leasingContractId) {
    return this.httpHelperService.post(`tasks/contract/leasing/canceled/${customerId}/${leasingContractId}`, {
      status: 'canceled',
    });
  }

  downloadContractDraft(customerId, leasingContractId) {
    return this.httpHelperService.downloadFilePost(
      `contract/leasing/${customerId}/${leasingContractId}/contract-pdf/download-draft`
    );
  }

  endContract(customerId, contractId, body) {
    return this.httpHelperService.post(`tasks/contract/leasing/end-contract/${customerId}/${contractId}`, body);
  }

  getPaymentsTypes(customerId, contractId) {
    return this.httpHelperService.get(`tasks/contract/leasing/end-contract/${customerId}/${contractId}/product`);
  }

  getTransactionTemplates(taskCode) {
    return this.httpHelperService.get(`contract/leasing-transaction/templates/getDetails?code=${taskCode}`);
  }

  getTransactionTypeDetails(transactionTypeId) {
    return this.httpHelperService.get(`transaction/${transactionTypeId}`);
  }

  getTerminationDate(contractId, startDate) {
    return this.httpHelperService.get(`contract/end-date/${contractId}?start_date=${startDate}`);
  }

  deleteContract(customerId, contractId) {
    return this.httpHelperService.delete(`contract/leasing/${customerId}/${contractId}/delete-signed`);
  }

  restoreContract(customerId, contractId) {
    return this.httpHelperService.post(`tasks/contract/leasing/restore-contract/${customerId}/${contractId}`, {});
  }

  importMileage(contractId, payload) {
    return this.httpHelperService.post(`contract/leasing/${contractId}/mileage/import`, payload);
  }

  updateMileage(contractId) {
    return this.httpHelperService.post(`contract/leasing/${contractId}/mileage/vehicle`, {});
  }

  deleteMileage(leasingId, customerId, id) {
    return this.httpHelperService.delete(`contract/leasing/${leasingId}/customer/${customerId}/mileage/${id}`);
  }

  getMissingTasks(customerId, contractId) {
    return this.httpHelperService.get(`tasks/contract/leasing/missing/${customerId}/${contractId}`);
  }

  extendContract(contractId, payload) {
    return this.httpHelperService.put(`contract/leasing/extend-contract/${contractId}`, payload);
  }

  getProductByCode(code) {
    return this.httpHelperService.get(`products/get_product_by_code/${code}`);
  }

  getAmortizationByContractId(contractId, filters) {
    return this.httpHelperService.get(`contract/amortizations/list/${contractId}`, filters);
  }

  getSettingsGeneral() {
    return this.httpHelperService.get(`settings/data/list/general`);
  }

  getSettingCalulator() {
    return this.httpHelperService.get(`settings/data/list/calculator`);
  }

  getSettingValue() {
    return this.httpHelperService.get(`settings/getValue`);
  }

  getCustomersContract(contractId) {
    return this.httpHelperService.get(`contract/leasing/${contractId}/customers`);
  }

  getTransactionsBySetId(setId) {
    return this.httpHelperService.get(`contract/transactions/${setId}/transactions-by-set`);
  }

  deleteTransaction(transaction_set_id) {
    return this.httpHelperService.delete(`contract/transactions/${transaction_set_id}/delete`);
  }

  showLockContractConfirmDialog(message?: string): Observable<boolean> {
    if (!message) message = this.translate.instant('generic.lock_contract_dialog.message');
    return new Observable((observer: Observer<boolean>) => {
      Swal.fire({
        title: this.translate.instant('generic.lock_contract_dialog.title'),
        text: message,
        iconHtml: '<img src="assets/media/icons/ic-warning.svg">',
        showCancelButton: true,
        confirmButtonText: this.translate.instant('generic.lock_contract_dialog.confirm_button_text'),
        cancelButtonText: this.translate.instant('generic.lock_contract_dialog.cancel_button_text'),
        reverseButtons: true,
        buttonsStyling: false,
        focusConfirm: false,
        focusCancel: false,
        focusDeny: false,
        allowEnterKey: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-tertiary',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          observer.next(true);
        } else {
          observer.error(false);
        }
        observer.complete();
      });
    });
  }

  unlockContractTask(contract_id: number, payload: { [key: string]: number[] }, allow: number = 1): Observable<any> {
    return this.httpHelperService.post(`admin/contracts/${contract_id}/tasks/update-lock?allow=${allow}`, payload);
  }

  getExpectedRegisteration(payload: ExpectationRegisterationPayload): Observable<ExpectationRegisterationResponse> {
    return this.httpHelperService.post(`calculation/expected_registration`, payload);
  }

  configCommonPayloadForTransactions(transactions): FormData {
    let formData = new FormData();
    console.log(transactions);
    transactions.forEach((transaction) => {
      transaction.transaction_date_value = this.datepipe.transform(transactions[0].transaction_date, 'dd-MM-yyyy');
    });
    transactions.forEach((element, index) => {
      if (transactions[index].vehicle_leasing_contract) {
        formData.append(
          `transactions[${index}][vehicle_leasing_contract]`,
          transactions[index].vehicle_leasing_contract
        );
      }
      if (transactions[index].category_code == 'finansiel') {
        formData.append(`transactions[${index}][transaction_type_id]`, transactions[index].transaction_type_id);
        if (transactions[index].vehicle_id) {
          formData.append(`transactions[${index}][vehicle_id]`, transactions[index].vehicle_id);
        }

        if (transactions[index].vat_code && transactions[index].vat_code != 'null') {
          formData.append(`transactions[${index}][vat_code_used]`, '1');
          formData.append(`transactions[${index}][vat_code_id]`, transactions[index].vat_code);
        } else {
          formData.append(`transactions[${index}][vat_code_used]`, '0');
        }

        if (transactions[index].transaction_contract == undefined) {
          formData.append(`transactions[${index}][transaction_contract]`, '');
        } else {
          formData.append(`transactions[${index}][transaction_contract]`, transactions[index].transaction_contract);
        }

        if (transactions[index].vehicle_transaction == undefined) {
          formData.append(`transactions[${index}][vehicle_transaction]`, '');
        } else {
          formData.append(
            `transactions[${index}][vehicle_transaction]`,
            transactions[index].vehicle_transaction ? '1' : '0'
          );
        }
      } else {
        formData.delete(`transactions[${index}][transaction_contract]`);
        formData.delete(`transactions[${index}][vehicle_id]`);
        formData.append(`transactions[${index}][vat_code_used]`, '0');
        formData.append(`transactions[${index}][vehicle_transaction]`, '0');
      }

      if (transactions[index].transaction_date || transactions[index].transaction_date_value) {
        formData.append(`transactions[${index}][transaction_date]`, transactions[index].transaction_date_value);
      }
      if (transactions[index].amount_cents) {
        formData.append(`transactions[${index}][amount_cents]`, transactions[index].amount_cents);
      }

      if (transactions[index].transaction_category_id == undefined) {
        formData.append(`transactions[${index}][transaction_category_id]`, '');
      } else {
        formData.append(`transactions[${index}][transaction_category_id]`, transactions[index].transaction_category_id);
      }
      if (transactions[index].payment_type == undefined) {
        formData.append(`transactions[${index}][payment_type]`, '');
      } else {
        formData.append(`transactions[${index}][payment_type]`, transactions[index].payment_type);
      }
      if (transactions[index].supplier_account == undefined) {
        formData.append(`transactions[${index}][supplier_account]`, '');
      } else {
        formData.append(`transactions[${index}][supplier_account]`, transactions[index].supplier_account);
      }
      if (transactions[index].bank_account == undefined) {
        formData.append(`transactions[${index}][bank_account]`, '');
      } else {
        formData.append(`transactions[${index}][bank_account]`, transactions[index].bank_account);
      }
      if (
        !transactions[index].category_user_supplier_id ||
        transactions[index].category_user_supplier_id == undefined ||
        transactions[index].category_user_supplier_id == null
      ) {
        formData.append(`transactions[${index}][category_user_supplier_id]`, '');
      } else {
        formData.append(
          `transactions[${index}][category_user_supplier_id]`,
          transactions[index].category_user_supplier_id
        );
      }
    });

    return formData;
  }
}
