// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState } from '../../../@core/reducers/';
import { isLoggedIn } from '../_selectors/auth.selectors';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../_services/index';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private auth: AuthService,
    private permissionsService: NgxPermissionsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // return this.store
    //     .pipe(
    //         select(isLoggedIn),
    //         tap(loggedIn => {
    //             if (!loggedIn) {
    //                 this.router.navigateByUrl('/auth/login');
    //             }
    //         })
    //     );
    const userToken = localStorage.getItem(environment.authTokenKey);
    if (userToken) {
      return of(true);
      // new Login({ authToken: userToken });
      // localStorage.setItem(environment.authTokenKey, userToken);
      // this.store.dispatch(new UserRequested());
      // if (!window.localStorage.getItem('user')) {
      //     return this.auth.getUserByToken().pipe(
      //         map((_user: any) => {
      //             const user = _user.data;
      //             if (user) {
      //                 this.store.dispatch(new UserLoaded({ user }));
      //                 if (user.permissions_list) {
      //                     this.permissionsService.flushPermissions();
      //                     this.permissionsService.loadPermissions(user.permissions_list.map(pm => pm.name));
      //                     return true;
      //                 } else {
      //                     return true;
      //                 }
      //             } else {
      //                 this.store.dispatch(new Logout());
      //                 return false;
      //             }
      //         })
      //     )
      // } else {
      //     return of(true);
      // }
    }
    this.router.navigateByUrl('/auth/login');
    return of(false);
  }
}
