/* eslint-disable prettier/prettier */
import { AppState } from './@core/reducers/index';
import { select, Store } from '@ngrx/store';
import { isLoggedIn } from './@core/auth/_selectors/auth.selectors';
import { Subject, Subscription } from 'rxjs';
// Angular
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ɵallowSanitizationBypassAndThrow,
} from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RouterEvent,
} from '@angular/router';
// import { TranslationService } from "./@core/services/translation.service";
// Layout
// language list
import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { Login } from './@core/auth';
import { HttpHelperService } from '../app/@shared/services/httpHelper.service';
import { UserService } from './pages/users/user.service';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { User } from '../app/@shared/models/user.model';
import { PermittedModule } from '../app/@shared/models/permitted-module.model';
import { SWService } from './@shared/services/sw.service';
@Component({
  // tslint:disable-next-line:component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[kt-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  // Public properties
  title = 'LEASO';
  loader: boolean;
  isLoggedIn;
  isHeaderPresented: string;
  appVersion = environment.appVersion;

  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  /**
   * Component constructor
   *
   * @param translationService: TranslationService
   * @param router: Router
   * @param layoutConfigService: LayoutCongifService
   * @param splashScreenService: SplashScreenService
   */

  userActivity;

  userInactive: Subject<any> = new Subject();

  session_lifetime = 8; //initial

  public loading: boolean;

  loadingRouteConfig: any;

  user: User;

  constructor(
    // private translationService: TranslationService,
    public router: Router,
    private titleService: Title,
    private store: Store<AppState>,
    private httpHelperService: HttpHelperService,
    private userService: UserService,
    private idle: Idle,
    private keepalive: Keepalive,
    private translate: TranslateService,
    private _SWService: SWService
  ) {
    if (window.localStorage.getItem('user')) {
      this.user = JSON.parse(window.localStorage.getItem('user'));
    }
    this.translate.setDefaultLang('da');
    this.translate.use('da');
    this.handleLoader();
    console.log('====== THE APP VERSION ====== ', this.appVersion);

    // register translations
    this.titleService.setTitle(environment.AppName);
    // this.translationService.loadTranslations(enLang);
    // this.translationService.loadTranslations(enLang);
    // this.isLoggedIn = this.store.select(isLoggedIn);

    // this.setTimer(0);
    // idle.setIdle(5);
    //     idle.setTimeout(10);
    //     idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    //     idle.onTimeoutWarning.subscribe((countdown:number) => {
    //       console.log('TimeoutWarning: ' + countdown);
    //     });

    //     idle.onTimeout.subscribe(() => {
    //       console.log('Timeout');
    //       // localStorage.clear();
    //       // this._router.navigate(['/auth', {sessionExpirate: 'true'}]);
    //     });
    //     idle.watch();
  }

  handleLoader() {
    this.loading = false;
    this.router.events.subscribe((event: RouterEvent): void => {
      if (event instanceof NavigationStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof NavigationEnd) {
        this.loadingRouteConfig = false;
      } else if (event instanceof NavigationCancel) {
        this.loadingRouteConfig = false;
      }
    });
  }

  setTimer(seconds: number) {
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(5);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(seconds);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
    });
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.logout();
    });
    this.idle.onIdleStart.subscribe(() => (this.idleState = "You've gone idle!"));
    this.idle.onTimeoutWarning.subscribe(
      (countdown) => (this.idleState = 'You will time out in ' + countdown + ' seconds!')
    );

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    // enable/disable loader
    this.listenToHeaderToggle();
    if (localStorage.getItem(environment.authTokenKey)) {
      this.isHeaderPresented = 'presented';
      this.getUserTimeout();
      this.getPermittedModule();
    }
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  listenToHeaderToggle() {
    this.httpHelperService.isHeaderPresented$.subscribe((res) => {
      if (res == null) {
      } else {
        this.isHeaderPresented = res;
        if (this.isHeaderPresented == 'presented') {
          this.getUserTimeout();
          this.getPermittedModule();
          // this.refreshUserState();
        }
      }
    });
  }

  getPermittedModule() {
    this.userService.getPermittedModule().subscribe((res: PermittedModule) => {
      let permittedModule = res.data.data;
      if (window.localStorage.getItem('permittedModule')) {
        let storedpermittedModule = JSON.parse(window.localStorage.getItem('permittedModule'));
        if (permittedModule['aml-feature'] != storedpermittedModule['aml-feature']) {
          if (this.user) {
            window.localStorage.removeItem(`${this.user.data.id}-CustomersListingComponent`);
            window.localStorage.removeItem(`${this.user.data.id}-organization-CustomersListingComponent`);
          }
        }
      }
      window.localStorage.setItem('permittedModule', JSON.stringify(permittedModule));
    });
  }

  getUserTimeout() {
    this.userService.getSessionTimeout().subscribe((res: any) => {
      //console.log('SESSION TIMEOUT',res.data.session_lifetime)
      this.session_lifetime = +res.data.session_lifetime * 1000;
      window.localStorage.setItem('company_logo', res.data.company_logo);
      window.localStorage.setItem('standard_payment_term', res.data.standard_payment_term);
      //this.session_lifetime = 60000;
      //this.detectInactivity(this.session_lifetime);
      this.setTimer(this.session_lifetime);
    });
  }

  /*   detectInactivity(number) {
    this.setTimeout(number);
    this.userInactive.subscribe(() => {
      console.log('user is inactive, logging out ...')

      this.logout();
    });
  } */

  /* setTimeout(number) {
    console.log('user inactive...')

    this.userActivity = setTimeout(
      () => this.userInactive.next(),
      number
    );
  } */

  timeout() {
    console.log(`user has been inactive for ${this.session_lifetime} minutes, logging out ... `);
    this.logout();
  }

  timerReset() {
    console.log('inactivity timer is reset');
  }
  @HostListener('window:mousemove') refreshUserState() {
    // clearTimeout(this.userActivity);
    // console.log('start counting again');
    // this.setTimeout(this.session_lifetime);
  }

  logout() {
    this.httpHelperService.logout().subscribe(
      (res: any) => {
        if (window.localStorage.getItem('leasoTokenKey')) {
          // here implement the logout feature
          console.log('Timeout !!!!!!!!!!!!!!!!!!!!');
          this.httpHelperService.handleError(null, 'sessionstiden er afsluttet');
          window.localStorage.removeItem('user');
          window.localStorage.removeItem('leasoTokenKey');
          window.localStorage.removeItem('permissionsList');
          this.httpHelperService.toggleHeader('notPresented');
          this.router.navigateByUrl('auth/login');
        }
      },
      (err) => {}
    );
  }
}
