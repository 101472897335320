export const environment = {
  envName: 'develop',
  production: true,
  isMockEnabled: false, // You have to switch this, when your real back-end is done
  authTokenKey: 'leasoTokenKey',
  ApiURL: 'https://new-api.leaso.dk/api',
  BaseUrl: 'https://new-api.leaso.dk',
  RollbarAccessToken: 'ddb92aec129e4d6a95b3f509b4bbd6dd',
  AppName: 'Leaso Develop',
  AppBaseURL: '',
  appVersion: require('../../package.json').version,
  dataCVRBaseUrl: 'https://datacvr.virk.dk/',
};
