// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { finalize, takeUntil, tap, catchError } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../@core/reducers';
// Auth
import { AuthNoticeService, AuthService, Register } from '../../../@core/auth';
import { Subject, of } from 'rxjs';
import { ConfirmPasswordValidator } from './confirm-password.validator';

@Component({
  selector: 'leaso-kt-reset-password',
  templateUrl: './reset-password.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  registerForm: FormGroup;
  loading = false;
  errors: any = [];

  private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  /**
   * Component constructor
   *
   * @param authNoticeService: AuthNoticeService
   * @param translate: TranslateService
   * @param router: Router
   * @param auth: AuthService
   * @param store: Store<AppState>
   * @param fb: FormBuilder
   * @param cdr
   */
  constructor(
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.unsubscribe = new Subject();
  }

  /*
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    this.initRegisterForm();
  }

  /*
   * On destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
  }

  /**
   * Form initalization
   * Default params, validators
   */
  initRegisterForm() {
    this.registerForm = this.fb.group(
      {
        email: ['', Validators.compose([])],
        password: ['', Validators.compose([])],
        password_confirmation: ['', Validators.compose([])],
      },
      {
        // validator: ConfirmPasswordValidator.MatchPassword
      }
    );
  }

  /**
   * Form Submit
   */
  submit() {
    const controls = this.registerForm.controls;

    // check form
    if (this.registerForm.invalid) {
      Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
      return;
    }

    this.loading = true;

    let _user: any = {};

    _user.email = controls['email'].value;
    _user.password = controls['password'].value;
    _user.password_confirmation = controls['password_confirmation'].value;
    _user.token = this.route.snapshot.queryParams['token'];

    this.auth
      .resetPassword(_user)
      .pipe(
        catchError((err) => {
          this.errors = err?.error?.errors;
          this.authNoticeService.setNotice(err?.error?.message, 'danger');
          return of(false);
        }),
        tap((res: any) => {
          if (res) {
            // pass notice message to the login page
            this.authNoticeService.setNotice(res.message, 'success');
            this.router.navigateByUrl('/auth/login');
          }
        }),
        takeUntil(this.unsubscribe),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe();
  }

  /**
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to valitors name
   */
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.registerForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
