<div class="confirm-dialog-container p-6">
  <div class="d-flex justify-content-center mb-4" *ngIf="data.path">
    <img width="56px" [src]="data.path" alt="confirm dialog logo" />
  </div>

  <h3 class="modal-header__title text-center" *ngIf="data?.title">
    {{ data?.title | translate }}
  </h3>
  <p class="modal-header__desc text-center" *ngIf="data?.message">
    {{ data?.message | translate }}
  </p>
  <div class="border-0 modal-footer justify-content-center">
    <button *ngIf="data.noTitle" class="btn btn-tertiary" (click)="onNoClick()">
      {{ data.noTitle | translate }}
    </button>

    <button *ngIf="data.yesTitle" [mat-dialog-close]="true" class="btn btn-primary">
      {{ data.yesTitle | translate }}
    </button>
  </div>
</div>
