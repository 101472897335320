import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmModel } from './confirm';
// TODO to be refactored
@Component({
  selector: 'leaso-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  // how to use
  // const dialogRef = this.dialog.open(ConfirmDialogComponent, {
  //   width: '35%',
  //   maxWidth: '410px',
  //   data: {
  //     title: "Delete ",
  //     message: "Are you sure you want to Delete this item?",
  //     yesTitle: "Yes",
  //     noTitle: "NO"
  //   }
  // });

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModel
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
