<!--begin::Body-->
<div class="logo-leaso">
  <div class="col-12">
    <div class="col-3">
      <div class="kt-grid__item">
        <img src="./assets/media/logos/leaso-log.png" alt="" />
      </div>
    </div>
  </div>

  <div class="leaso-kt-login__body">
    <!--begin::Signin-->
    <div class="card kt-login__form">
      <leaso-leaso-kt-auth-notice></leaso-leaso-kt-auth-notice>
      <div class="kt-login__title">
        <h3>{{ 'AUTH.RESET_PASSWORD.TITLE' | translate }}</h3>
      </div>

      <form class="kt-form" [formGroup]="registerForm" autocomplete="off">
        <div class="form-group">
          <div class="form-field">
            <i class="fa fa-user"></i>
            <input
              matInput
              type="email"
              placeholder="{{ 'AUTH.LOGIN.EMAIL' | translate }}"
              formControlName="email"
              autocomplete="off"
            />
          </div>
          <mat-error *ngIf="errors?.email">
            <strong>{{ errors?.email[0] }}</strong>
          </mat-error>
          <!-- <mat-error *ngIf="isControlHasError('email','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','email')">
						<strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
					</mat-error> -->
        </div>
        <div class="form-group">
          <div class="form-field">
            <i class="fa fa-lock"></i>
            <input
              matInput
              type="password"
              placeholder="{{ 'AUTH.LOGIN.PASSWORD' | translate }}"
              formControlName="password"
              autocomplete="off"
            />
          </div>
          <mat-error *ngIf="errors?.password">
            <strong>{{ errors?.password[0] }}</strong>
          </mat-error>
          <!-- <mat-error *ngIf="isControlHasError('password','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('password','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('password','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error> -->
        </div>
        <div class="form-group">
          <div class="form-field">
            <i class="fa fa-lock"></i>
            <input
              matInput
              type="password"
              placeholder="{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}"
              formControlName="password_confirmation"
              autocomplete="off"
            />
          </div>
        </div>

        <!--begin::Action-->
        <div class="mb-4">
          <button
            (click)="submit()"
            id="kt_login_signin_submit"
            class="btn btn-primary w-100 reset-password"
            [ngClass]="{ 'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading }"
          >
            {{ 'AUTH.RESET_PASSWORD.TITLE' | translate }}
          </button>
        </div>
        <!--end::Action-->
      </form>
    </div>
  </div>
</div>
