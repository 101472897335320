import { ApplicationRef, Injectable } from '@angular/core';
import { HttpHelperService } from './httpHelper.service';
import { SwUpdate } from '@angular/service-worker';
import { Router } from '@angular/router';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SWService {
  constructor(
    private _http: HttpHelperService,
    private _appRef: ApplicationRef,
    private _updates: SwUpdate,
    private _router: Router
  ) {
    this._observeUpdates();
    this._checkForUpdates();
    this._onNewVersionFound();
  }

  private _onNewVersionFound() {
    const _logout = () => {
      localStorage.clear();
      this._router.navigateByUrl('auth/login');
      document.location.reload();
    };
    this._updates.available.subscribe((event) => {
      this._updates.activateUpdate().then(() => {
        this._http.logout().subscribe(
          (_) => _logout(),
          (_) => _logout()
        );
      });
    });
  }

  private _checkForUpdates() {
    const appIsStable$ = this._appRef.isStable.pipe(first((isStable) => isStable === true));
    const everyMinute$ = interval(60 * 1000);
    const everyMinuteAppStable$ = concat(appIsStable$, everyMinute$);

    everyMinuteAppStable$.subscribe(() => this._updates.checkForUpdate());
  }

  private _observeUpdates() {
    this._updates.available.subscribe((event) => {
      console.log('current version is', event.current);
      console.log('available version is', event.available);
    });
    this._updates.activated.subscribe((event) => {
      console.log('old version was', event.previous);
      console.log('new version is', event.current);
    });
  }
}
